<template>
  <div class="content">
    <div class="attachments--list">
      <item
        v-for="attachment in sortedAttachments"
        :key="attachment.id"
        :allowed-access="allowRemove"
        :model="attachment"
        :selected="selected.includes(attachment.id)"
        :images-for-viewer="imagesForViewer"
        @remove="confirmDelete"
        @toggleItem="toggleItem"
      />
    </div>
    <confirm-modal
      title=""
      :subject="selected"
      :show="deleteItems"
      @close="deleteItems = false"
      @cancel="cancelRemove"
      @confirm="actionDelete"
    >
      Czy na pewno chcesz usunąć?
    </confirm-modal>
    <ZipPasswordModal
      :service="service"
      :file-uuids="fileUuidsToEncrypt"
      :taskNumber="taskNumber"
      :show="encryptModalOpened"
      @close="closeEncryptModal"
      @success="closeEncryptModal"
    >
    </ZipPasswordModal>
    <blocking-loader :show="loading">
      {{ this.loaderMessage }}
    </blocking-loader>
  </div>
</template>

<script>
import api from '../../../../../../api'
import BlockingLoader from '../../../../../share/BlockingLoader'
import ConfirmModal from '../../../../../share/modal/BaseConfirmModal'
import Item from './Item.vue'
import buttonsStateBlockingMixin
  from '../../../mixins/actionBlockingMixin'
import ZipPasswordModal from '../../../../share/attachment/modals/ZipPasswordModal'

export default {
  components: {
    ZipPasswordModal,
    BlockingLoader,
    ConfirmModal,
    Item
  },
  mixins: [
    buttonsStateBlockingMixin
  ],
  props: {
    taskState: { type: Object },
    activeTab: { type: String, required: true },
    // path: {type: String, required: true},
    attachments: { type: Object, required: true },
    imagesForViewer: {},
    service: { type: String, required: true, default () { return '' } },
    taskId: { type: Number, default () { return 0 } },
    taskNumber: { default () { return '' } },
    taskType: { type: String, default () { return 'main' } },
    activeFilters: {
      type: Object,
      default () {
        return {
        }
      }
    }
  },
  data () {
    return {
      events: {
        invertSelection: `task:attachments:invertSelection`,
        downloadSelected: `${this.service}:${this.taskType}:downloadSelectedAttachments`,
        download: `${this.service}:${this.taskType}:downloadAttachments`,
        removeSelected: `${this.service}:${this.taskType}:removeSelectedAttachments`,
        sendToAudanet: `${this.service}:${this.taskType}:sendAttachmentToAudanet`
      },
      files: this.attachments,
      selected: [],
      deselectAll: false,
      deleteItems: false,
      loading: false,
      loaderMessage: 'Trwa wysyłanie plików. Proszę czekać.',
      fileUuidsToEncrypt: [],
      encryptModalOpened: false
    }
  },
  computed: {
    sortedAttachments () {
      const sortAlphaNum = (a, b) => a.filename.localeCompare(b.filename, 'pl', { numeric: true })
      return Object.values(this.attachments).sort(sortAlphaNum)
    },
    thirdMenu () {
      if (!this.taskState.final) {
        return [
          ...this.getActionBlockade([{
            event: { name: `${this.service}:addSettlementAttachment` },
            icon: 'plus',
            label: 'Dodaj załącznik',
            acl: { action: 'post_settlement_attachments', service: this.service }
          }]),
          {
            event: { name: `task:attachments:invertSelection` },
            icon: 'check-square',
            label: 'Odwróć zaznaczenie',
            acl: { security: false }
          },
          {
            event: { name: `${this.service}:${this.taskType}:downloadSelectedAttachments` },
            icon: 'download',
            label: 'Pobierz zaznaczone',
            acl: { security: false }
          },
          {
            event: { name: `${this.service}:${this.taskType}:downloadAttachments` },
            icon: 'download',
            label: 'Pobierz wszystkie',
            acl: { security: false }
          },
          {
            event: { name: `${this.service}:${this.taskType}:removeSelectedAttachments` },
            icon: 'trash-2',
            label: 'Usuń zaznaczone',
            acl: { service: this.service, action: 'delete_settlement_attachments' }
          },
          {
            event: { name: `${this.service}:${this.taskType}:sendAttachmentToAudanet` },
            icon: 'skip-forward',
            label: 'Wyślij do Audanet',
            acl: { service: this.service, action: 'post_audanet_attachment' }
          }
        ]
      } else {
        return [
          {
            event: { name: `task:attachments:invertSelection` },
            icon: 'check-square',
            label: 'Odwróć zaznaczenie',
            acl: { security: false }
          },
          {
            event: { name: `${this.service}:${this.taskType}:downloadSelectedAttachments` },
            icon: 'download',
            label: 'Pobierz zaznaczone',
            acl: { security: false }
          },
          {
            event: { name: `${this.service}:${this.taskType}:downloadAttachments` },
            icon: 'download',
            label: 'Pobierz wszystkie',
            acl: { security: false }
          },
          {
            event: { name: `${this.service}:${this.taskType}:removeSelectedAttachments` },
            icon: 'trash-2',
            label: 'Usuń zaznaczone',
            acl: { service: this.service, action: 'delete_settlement_attachments' }
          },
          {
            event: { name: `${this.service}:${this.taskType}:sendAttachmentToAudanet` },
            icon: 'skip-forward',
            label: 'Wyślij do Audanet',
            acl: { service: this.service, action: 'post_audanet_attachment' }
          }
        ]
      }
    },
    allowRemove () {
      return this.$auth.isAllowed({ service: [this.service, 'storage'], action: 'delete_settlement_attachments' })
    }
  },
  watch: {
    activeTab: function (newVal) {
      this.showMenu(newVal)
    },
    attachments: function (newVal) {
      this.files = newVal
    },
    activeFilters: {
      deep: true,
      handler: function (newVal) {
        this.revealAll()
      }
    }
  },
  mounted () {
    this.$events.on(this.events.invertSelection, this.invertSelection)
    this.$events.on(this.events.download, this.download)
    this.$events.on(this.events.downloadSelected, this.downloadSelected)
    this.$events.on(this.events.removeSelected, this.confirmDelete)
    this.$events.on(this.events.sendToAudanet, this.sendToAudanet)

    this.showMenu(this.activeTab)
  },
  methods: {
    confirmDelete (uuid) {
      this.selected.push(uuid)

      this.deleteItems = true
    },
    toggleItem (uuid) {
      if (this.selected.includes(uuid)) {
        this.selected.splice(this.selected.indexOf(uuid), 1)
      } else {
        this.selected.push(uuid)
      }
    },
    download () {
      let uuids = Object.keys(this.attachments)
      if (uuids.length > 1 && this.$store.state.base.user.isAttachmentPassword) {
        this.fileUuidsToEncrypt = uuids
        this.openEncryptModal()
        return
      }
      this.actionDownload(uuids)
    },
    downloadSelected () {
      let selectedVisible = this.selected.filter(uuid => this.files[uuid].hidden === false)

      if (selectedVisible.length > 1 && this.$store.state.base.user.isAttachmentPassword) {
        this.fileUuidsToEncrypt = selectedVisible
        this.openEncryptModal()
        return
      }
      this.actionDownload(selectedVisible)
    },
    actionDownload (uuids) {
      let search = {
        filesUuids: uuids
      }

      api.request('storage', 'post', `/download/${this.service}/search`, search)
        .then((response) => {
          let file = response.data
          this.downloadBlob(this.base64toBlob(file.value, file.mimeType), uuids.length === 1 ? `${file.name}` : `${this.taskNumber}_załączniki.zip`)
          this.selected.splice(0, this.selected.length)
        })
        .catch(() => {
          this.toggleLoading()
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można pobrać pliku'
          })
        })
    },
    remove (uuids) {
      if (uuids.indexOf(',') === -1) {
        this.$emit('remove', uuids)
      } else {
        this.selected.forEach((el) => {
          this.$emit('remove', el)
        })
      }
      this.selected.splice(0, this.selected.length)
    },
    cancelRemove () {
      this.selected.splice(0, this.selected.length)
      this.deselect()
      this.deleteItems = false
    },
    actionDelete (uuids) {
      if (Array.isArray(uuids)) {
        uuids = uuids.join()
      }

      api.request('storage', 'delete', `/upload/${this.service}/${uuids}`)
        .then((response) => {
          this.deleteServiceAttachments(this.service, uuids)
          this.deleteItems = false
          document.querySelector('#tab-header-settlement > a').click()
        })
        .catch(() => {
          this.toggleLoading()
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można usunąć pliku'
          })
        })
    },
    deleteServiceAttachments (service, uuids) {
      if (Array.isArray(uuids)) {
        uuids = uuids.join()
      }

      api.request(service, 'delete', `/settlements/attachments/${uuids}`)
        .then((response) => {
          this.$notify({
            type: 'success',
            text: 'Usunięto załącznik'
          })
        })
        .catch(() => {
          this.toggleLoading()
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można usunąć pliku z aplikacji'
          })
        })
    },
    downloadBlob (blob, filename) {
      let a = document.createElement('a')
      a.href = URL.createObjectURL(blob)
      a.download = filename
      document.body.appendChild(a)
      a.click()
    },
    base64toBlob (b64Data, contentType, sliceSize) {
      contentType = contentType || ''
      sliceSize = sliceSize || 512

      let byteCharacters = atob(b64Data)
      let byteArrays = []

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        let slice = byteCharacters.slice(offset, offset + sliceSize)

        let byteNumbers = new Array(slice.length)
        for (let c = 0; c < slice.length; c++) {
          byteNumbers[c] = slice.charCodeAt(c)
        }

        let byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
      }

      return new Blob(byteArrays, { type: contentType })
    },
    deselect () {
      this.deselectAll = true
      this.$nextTick(() => {
        this.deselectAll = false
      })
    },
    invertSelection () {
      let visible = Object.keys(this.files).filter(uuid => this.files[uuid].hidden === false)
      this.selected = visible.filter(
        element => !this.selected.find(
          selection => selection === element
        )
      ).map(element => element)
    },
    hide (uuid) {
      this.files[uuid].hidden = true
    },
    reveal (uuid) {
      this.files[uuid].hidden = false
    },
    revealAll () {
      for (let uuid in this.files) {
        this.reveal(uuid)
      }
    },
    openEncryptModal () {
      this.encryptModalOpened = true
    },
    closeEncryptModal () {
      this.encryptModalOpened = false
      this.fileUuidsToEncrypt = []
    },
    formatDate (dateVal, withTime = false) {
      const date = new Date(dateVal)
      let formatedDate = date.toLocaleDateString('pl-PL')
      if (withTime) {
        formatedDate += ' @ ' + date.toLocaleTimeString('pl-PL').substr(0, 5)
      }
      return formatedDate
    },
    showMenu (tab) {
      if (tab === 'attachments') {
        this.$events.$emit('dashboard:menu:taskTab', this.thirdMenu)
      }
    },
    showLoader () {
      this.loading = true
    },
    hideLoader () {
      this.$nextTick(() => {
        this.loading = false
      })
    }
  }
}
</script>
