export default {
  methods: {
    getActionBlockade (button) {
      return button
      // let state = this.$store.state.settlementsState.settlement.state
      // let userRoles = this.$store.state.base.user.roles
      // let menu = []
      // if (
      //   userRoles.includes('ROLE_SUPER_ADMIN')
      // ) {
      //   menu = button
      //   return menu
      // }
      // switch (state.final) {
      //   case false:
      //     if (userRoles.includes('ROLE_RZECZOZNAWCA_ZEWN')) {
      //       menu = button
      //     }
      //     if (userRoles.includes('ROLE_KIEROWNIK_BLS')) {
      //       menu = []
      //     }
      //     break
      //   case true:
      //     if (userRoles.includes('ROLE_RZECZOZNAWCA_ZEWN')) {
      //       menu = []
      //     }
      //     if (userRoles.includes('ROLE_KIEROWNIK_BLS')) {
      //       menu = button
      //   }
      //     break
      // }
      // return menu
    }
  }
}
