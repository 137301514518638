<template>
  <form>
    <div
      class="form-group required-field"
      :class="[{ 'has-error': vErrors.first('comment'), 'has-success' : !vErrors.first('comment') && value['comment'] }]"
    >
      <label for="comment">
        Treść
      </label>
      <textarea
        id="comment"
        v-model="value['comment']"
        v-validate="{rules: {required: true, max: 1024}}"
        name="comment"
        class="form-control"
        cols="60"
        rows="6"
        placeholder="Wpisz treść... Komunikat nie może przekroczyć 1024 znaków."
        :required="true"
        data-vv-as="Komunikat"
      />
      <span
        v-show="vErrors.has('comment')"
        class="help-block"
      >{{ vErrors.first('comment') }}</span>
    </div>
  </form>
</template>

<script>
import FormModalValidation from '../../../../../share/form/FormModalValidation'

export default {
  mixins: [
    FormModalValidation
  ],
  props: {
    value: {}
  }
}
</script>
