export default {
  data () {
    return {
      events: {}
    }
  },
  mounted () {
    this.$getEvents()
    this.$events.on(this.events.addSettlementComment,
      this.$addSettlementComment)
    this.$events.on(this.events.changeState,
      () => this.$changeState)
    this.$events.on(this.events.addSettlementAttachment,
      this.addSettlementAttachment)
    this.$events.on(this.events.stateRefuse,
      () => this.setSettlementState('0'))
    this.$events.on(this.events.stateVerify,
      () => this.setSettlementState('2'))
    this.$events.on(this.events.stateVerifyWithInvoice,
      () => this.setSettlementState('1'))
    this.$events.on(this.events.stateAccept,
      () => this.$addInvoiceDataModal())
    this.$events.on(this.events.getConfirmation,
      () => this.getSettlementFiles('confirmation'))
    this.$events.on(this.events.getReport,
      () => this.getSettlementFiles('report'))
    this.$events.on(this.events.editSettlementItemComment,
      (payload) => this.$addSettlementItemComment(payload))
    this.$events.on(this.events.editSettlementItemAmount,
      (payload) => this.$editSettlementItemAmount(payload))
    this.$events.on(this.events.refreshCommentItem,
      () => this.getSettlementData())

  },
  methods: {
    $getEvents () {
      this.events = {
        addSettlementComment: `${this.service}:addSettlementComment`,
        changeState: `${this.service}:changeState`,
        addSettlementAttachment: `${this.service}:addSettlementAttachment`,
        stateRefuse: `${this.service}:state:refuse`,
        stateVerify: `${this.service}:state:verify`,
        stateVerifyWithInvoice: `${this.service}:state:verifyWithInvoice`,
        stateAccept: `${this.service}:state:accept`,
        getConfirmation: `${this.service}:get:confirmation`,
        getReport: `${this.service}:get:report`,
        editSettlementItemComment: `${this.service}:settlementItem:comment:edit`,
        editSettlementItemAmount: `${this.service}:settlementItem:amount:edit`,
        refreshCommentItem: `settlement:refresh:comment`
      }
    },
  }
}
