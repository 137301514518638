<template>
  <base-form-modal
    :show="show"
    :title="'Dodaj dane faktury'"
    :ok-text="'Zaakceptuj'"
    :cancel-text="cancelText"
    @show="open"
    @close="$emit('close')"
    @beforeSubmit="bool => beforeSubmitTrigger = bool"
  >
    <form-modal
      ref="form"
      v-model="model"
      :is-before-submit="beforeSubmitTrigger"
    />
  </base-form-modal>
</template>

<script>
  import BaseFormModal from '../../../../../share/modal/BaseFormModal'
  import FormModalMixin from '../../../../../share/modal/FormModalMixin'
  import FormModal from './AddInvoiceForm'
  import ValidatorReset from '../../../../../share/mixins/ValidatorReset'
  import api from '../../../../../../api'

  export default {
    components: {
      FormModal,
      BaseFormModal
    },
    mixins: [
      FormModalMixin,
      ValidatorReset
    ],
    props: {
      service: { type: String, default: '' }
    },
    computed: {
      ownership () {
        return this.$store.state.settlementsState.settlementDatatablesOwnership
      }
    },
    mounted () {
    },
    methods: {
      submitForm () {
        try {
          this.toggleLoading()
          this.addInvoice({
            id: this.$route.params.id,
            invoiceNumber: this.model.invoiceNumber,
            invoiceDate: this.model.invoiceDate,
            service: this.service,
          })
        } catch (e) {
          this.errorNotify(e)
        }
      },
      addInvoice (data) {
        api.request(data.service, 'put', `/settlements/${data.id}`, {
          invoiceNumber: data.invoiceNumber,
          invoiceDate: data.invoiceDate,
        }).then((response) => {
          this.toggleLoading()
          this.$emit('success')
          this.$emit('close')
          this.$notify({
            type: 'success',
            title: 'Sukces',
            text: 'Dane do faktury dodane poprawnie'
          })
        }).catch((error) => {
          this.toggleLoading()
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: error.response.data.message
          })
        })
      }
    }
  }
</script>
