<template>
  <form>
    <div style="max-height: 30vh; overflow-x: auto; overflow-y: auto">
      <SettlementComments
        :comments="sortedComments"
      />
    </div>

    <div
      v-if="value"
      class="form-group required-field"
      :class="[{ 'has-error': vErrors.first('message'), 'has-success' : !vErrors.first('message') && value['message'] }]"
    >
      <label for="message">
        Treść
      </label>
      <textarea
        id="message"
        v-model="value['comment']"
        v-validate="{rules: {}}"
        name="message"
        class="form-control"
        cols="60"
        rows="6"
        placeholder="Wpisz treść..."
        data-vv-as="komunikat"
      />
      <span
        v-show="vErrors.has('message')"
        class="help-block"
      >{{ vErrors.first('message') }}</span>
    </div>
  </form>
</template>

<script>
import FormModalValidation from '../../../../../share/form/FormModalValidation'
import SettlementComments from '../../Comments/SettlementComments'
import moment from 'moment'

export default {
  components: { SettlementComments },
  mixins: [
    FormModalValidation
  ],
  props: {
    currentlyCommentedItem: {type: Object,
      default: () => {}},
    value: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    sortedComments () {
      let comments = this.currentlyCommentedItem['comments']
      if (comments && comments.length !== 0) {
        return comments.sort(function (a, b) {
          return moment(a.comment.createdAt) - moment(b.comment.createdAt)
        }).reverse()
      } else {
        return []
      }
    }
  }
}
</script>
