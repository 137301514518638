<template>
  <form>
    <div
      v-if="value"
      class="form-group required-field"
      :class="[{ 'has-error': vErrors.first('amount'), 'has-success' : !vErrors.first('amount') && value['amount'] }]"
    >
      <money-type
        v-model="value['amount']"
        v-validate="{ rules: { required: true, money: 'money' }}"
        :error-message="vErrors.first('amount')"
        :required="true"
        label="Edytuj kwotę faktury"
        name="amount"
        data-vv-value-path="value"
        data-vv-as="kwota"
      />
      <span
        v-show="vErrors.has('message')"
        class="help-block"
      >{{ vErrors.first('amount') }}</span>
    </div>
  </form>
</template>

<script>
import FormModalValidation from '../../../../../share/form/FormModalValidation'
import MoneyType from '../../../../../share/form/type/MoneyType'
import { Validator } from 'vee-validate'

export default {
  components: { MoneyType },
  mixins: [
    FormModalValidation
  ],
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  created () {
    Validator.extend('money', {
      getMessage: field => 'Pole ' + field + ' musi być większe od 0 zł.',
      validate: (value) => {
        if (parseFloat(value.toString().replace(',', '.')) <= 0) {
          return false
        }
        return true
      }
    })
  }
}
</script>
