<script>
  import $ from 'jquery'
  import BaseListing from '../../../../bundles/share/ListingWithEditMode'
  import BlockingLoader from '../../../../../components/share/BlockingLoader'

  export default {
    components: { BlockingLoader },
    mixins: [
      BaseListing
    ],
    props: {
      settlementUuid: { required: true }
    },
    data () {
      return {
        events: null,
        showLoader: false,
        service: this.$route.meta.acl.service,
        headerTitle: { title: 'Historia zmian rozliczenia', description: 'Lista' },
        gridName: 'grid_settlement_history',
        gridColumns: [
          {
            className: 'details-control',
            searchable: false,
            orderable: false,
            render (data, type, row, meta) {
              let limit = 25

              if (window.matchMedia('(min-width: 1600px)').matches) {
                limit = 41
              } else if (window.matchMedia('(min-width: 1200px) and (max-width: 1599px)').matches) {
                limit = 30
              } else if (window.matchMedia('(max-width: 1199px)').matches) {
                limit = 20
              }
              let icon = ''
              if (row.prevValue.length > limit || row.value.length > limit || row.propertyLabel.length > limit) {
                icon = '<i class="feather-chevron-right padded pull-left"></i>'
              }
              return icon
            }
          },
          { id: 0, data: 'propertyLabel', title: 'Nazwa pola', orderable: false },
          { id: 1, data: 'prevValue', title: 'Poprzednia wartość', orderable: false },
          { id: 2, data: 'value', title: 'Wartość', orderable: false },
          { id: 3, data: 'user', title: 'Osoba zmieniająca', orderable: false },
          { id: 4, data: 'createdAt', title: 'Data zmiany', orderable: false }
        ]
      }
    },
    computed: {
      gridDataSource () {
        return {
          service: 'history',
          url: `/${this.service}/subjects/settlement/${this.settlementUuid}/changes`
        }
      }
    },
    created () {
      this.showLoader = false
      this.getInitEvents()
    },
    mounted () {
      this.expandRowListener()
      this.$events.on(this.events.historyLoaded, this.hideLoader)
    },
    beforeDestroy () {
      $(`#${this.gridName}`).off('click', 'i.pull-left', this.expandRow)
    },
    methods: {
      getInitEvents () {
        this.events = {
          historyLoaded: `${this.gridName}:dataTableLoaded`
        }
      },
      hideLoader () {
        this.showLoader = false
      },
      format (d) {
        // `d` is the original data object for the row
        return '' +
          '<div class="col-xs-12 bordered"><div class="col-xs-2 text-bold align-right">Nazwa pola: </div>' + '<div class="history-values col-xs-7">' + d.propertyLabel + '</div><div class="borderline col-xs-9"></div></div>' +
          '' +
          '<div class="col-xs-12 bordered"><div class="col-xs-2 text-bold align-right">Poprzednia wartość: </div>' + '<div class="history-values col-xs-7">' + (d.prevValue || '-') + '</div><div class="borderline col-xs-9"></div></div>' +
          '<div class="col-xs-12 bordered"><div class="col-xs-2 text-bold align-right">Wartość: </div>' + '<div class="history-values col-xs-7">' + (d.value || '-') + '</div><div class="borderline col-xs-9"></div></div>' +
          ''
      },
      expandRow (e) {
        let vm = this
        let tr = $(e.currentTarget).closest('tr')
        let row = vm.$children[0].table.row(tr)
        let icon = $(e.currentTarget).closest('i.pull-left')
        if (row.child.isShown()) {
          // This row is already open - close it
          row.child.hide()
          tr.removeClass('shown')
          icon.removeClass('down')
          icon.addClass('right')
        } else {
          // Open this row
          row.child(vm.format(row.data())).show()
          tr.addClass('shown')
          icon.removeClass('right')
          icon.addClass('down')
        }
      },
      expandRowListener () {
        $(`#${this.gridName}`).on('click', 'i.pull-left', this.expandRow)
      }
    },
    template: `
    <div>
         <h2 class=" tab-header">Historia</h2>
         <div>
         <grid :source="gridDataSource"
               :name="gridName"
               :columns="gridColumns"
               :defaultOrder="[[4, 'asc']]"
               ref="historyDatatable"
               >
         </grid>
         </div>
         <blocking-loader
         :show="showLoader"
         ></blocking-loader>
    </div>`
  }
</script>
<style>
  .history-values {
    word-break: break-all;
    white-space: normal;
  }
  .padded {
    padding: 10px;
    cursor: pointer;
  }
  .align-right {
    text-align: right;
  }
  .right {
    transition: all 0.5s;
  }
  .borderline {
    height: 1px;
    background-color: #42a5f6;
    margin-left: 2.333%;
  }
  .down {
    transform: rotate(90deg);
    transition: all 0.5s;
  }
</style>
