<template>
  <div :class="number ? 'comment-settlement-item' : 'comment-settlement'">
    <div class="comment-header">
      <p>
        <span v-if="number">{{ number }}</span>
        {{ createdBy }} |
        {{ settlement || settlementItem }} | {{ formatDate(createdAt.timestamp) }} | napisał(a):
      </p>
    </div>
    <div class="comment-body">
      <div class="comment-avatar">
        <img
          ref="avatarImage"
          class="img-responsive"
          :src="convertImg(avatar)"
        >
      </div>
      <div class="comment-content">
        <p>{{ content }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      content: {type: String, default () {
          return ''
        }},
      createdBy: {type: String, default () {
          return ''
        }},
      number: {type: String, default () {
          return ''
        }},
      createdAt: {type: Object, default () {
          return ''
        }},
      settlement: {type: Number , default () {
          return null
        }},
      settlementItem: {type: Number , default () {
          return null
        }},
      avatar: { type: String, default () { return '' } }
    },
    methods: {
      // @param dateVal 2017-12-01 14:49:37
      formatDate (unixTimestamp) {
        const date = new Date(unixTimestamp * 1000)
        return date.toLocaleDateString('pl-PL') + ' ' + date.toLocaleTimeString('pl-PL').substr(0, 5)
      },
      // formatDate (dateVal) {
      //   const date = new Date(dateVal)
      //   return date.toLocaleDateString('pl-PL') + ' ' + date.toLocaleTimeString('pl-PL').substr(0, 5)
      // },
      convertImg (avatar) {
        if (avatar !== undefined) {
          return `data:image-png;base64,${avatar}`
        }
      }
    }
  }
</script>

<style scoped>
.comment-settlement-item .comment-content {
  padding: 10px;
  background: rgba(197, 255, 181, 0.91);
}
</style>
