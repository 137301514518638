<template>
  <section class="content">
    <blocking-loader :show="changingState">
      {{ loadingMessage }}
    </blocking-loader>
    <div class="row">
      <div
        v-if="vuexSettlement"
        class="details-screen"
      >
          <SettlementHeader
            :data="vuexSettlement"
            @task-header-shadow="m$handleTaskHeaderShadow"
            @task-header-collapsed="m$handleTaskHeaderCollapsed"
          />
        <tabs
          ref="tabs"
          :tabs-data="tabsData"
          class="task-form"
          @clickedTab="$changeTab"
          :topDistance="m$tabsTopDistance"
        >
          <div slot="settlement">
            <div class="box box-default box-solid box-component">
              <SettlementItemsComponentVue
                slot="accepted"
                :items="settlementSentItems"
                :service="service"
                :header-name="'Zaakceptowane'"
                :item-type-color="'rgb(128, 253, 128)'"
              />
            </div>
            <div
              v-if="vuexSettlement && vuexSettlementItems.length !== 0"
              class="box box-default box-solid box-component"
            >
              <Summary
                :vuex-settlement="vuexSettlement"
              ></Summary>
            </div>
            <div class="box box-default box-solid box-component">
              <SettlementItemsComponentVue
                slot="completed"
                :items="settlementCompletedItems"
                :service="service"
                :header-name="'Zrealizowane'"
                :item-type-color="'#42a5f6'"
              />
            </div>
          </div>
          <div slot="history">
            <FinanceHistory
              v-if="activeTab === 'history'"
              :settlement-uuid="vuexSettlement.uuid"
            />
          </div>
          <div
            slot="comments"
            style="display: flex; justify-content: center;"
          >
            <div style="width: 50%;">
              <SettlementComments
                :service="service"
                :is-final="vuexSettlement.state === 3"
                :active-tab="activeTab"
                :comments="sortedComments"
              />
            </div>
          </div>
          <div slot="attachments">
            <Attachments
              v-if="activeTab === 'attachments'"
              :task-state="taskStateForAttachments"
              :active-tab="activeTab"
              :settlement-id="vuexSettlement.id"
              :path="pathForAttachments"
              :service="service"
            />
          </div>
        </tabs>
        <AddSettlementItemAmountModal
          :show="itemAmountModalVisible"
          :currently-edited-amount-item="currentlyEditedAmountItem"
          :service="service"
          @close="() => itemAmountModalVisible = false"
        />
        <AddSettlementItemCommentModal
          :show="itemCommentModalVisible"
          :currently-commented-item="currentlyCommentedItem"
          :service="service"
          @close="$closeCommentModal"
        />
        <AddSettlementCommentModal
          :show="settlementCommentModalVisible"
          :settlement="vuexSettlement"
          :service="service"
          @close="$closeSettlementCommentModal"
        />
        <RefuseCommentModal
          :show="refuseCommentModalVisibility"
          :service="service"
          @success="$closeRefuseCommentModal"
          @close="$closeRefuseCommentModal"
        />
        <ChangeSettlementStateModal
          :show="changeStateWithCommentModalVisible"
          :service="service"
          :state="changeStateWithCommentTargetState"
          @success="$closeChangeStateWithCommentModal"
          @close="$closeChangeStateWithCommentModal"
        />
        <AddInvoiceModal
          :show="addInvoiceModalVisibility"
          :service="service"
          @success="() => setSettlementState('3')"
          @close="$closeInvoiceModal"
        />
        <change-state-modal
          :isFormValid="isFormValid"
          :changeStateEvent="events.changeState"
          :taskId="this.$route.params.id"
          :show="changeStateVisible"
          :simpleTransition="simpleTransition"
          @success="$changeStateVisible = false"
          @close="changeStateVisible = false"
          :service="service"
        />
      </div>
    </div>
    <form
      ref="form"
      class="attachments--file-form"
      enctype="multipart/form-data"
    >
      <input
        ref="file"
        type="file"
        class="attachments--file-input"
        @input="setFiles"
      >
    </form>
  </section>
</template>

<script>
  import buttonsMixin from '../mixins/buttonsMixin'
  import ErrorNotify from '../../../share/mixins/ErrorNotify'
  import AddSettlementItemCommentModal
    from './modal/SettlementItemComment/AddSettlementItemCommentModal'
  import AddSettlementCommentModal
    from './modal/SettlementComment/AddSettlementCommentModal'
  import DownloadBlob from '../../../share/mixins/DownloadBlobFromBase64'
  import RefuseCommentModal from './modal/RefuseCommentModal/RefuseCommentModal'
  import AddInvoiceModal from './modal/AddInvoiceModal/AddInvoiceModal'
  import { mapActions, mapState, mapGetters } from 'vuex'
  import BlockingLoader from '../../../share/BlockingLoader'
  import Tabs from '../../../share/tabs/Tabs'
  import FinanceHistory from './History/FinanceHistory'
  import AddSettlementItemAmountModal
    from './modal/SettlemenItemAmount/AddSettlementItemAmountModal'
  import SettlementComments from './Comments/SettlementComments'
  import Attachments from './attachment/Attachments'
  import moment from 'moment'
  import tabsMixin from '../mixins/tabsMixin'
  import eventsMixin from '../mixins/eventsMixin'
  import modalsMixin from '../mixins/modalsMixin'
  import Summary from './summary/Summary'
  import ChangeSettlementStateModal
    from './modal/changeSettlementStateModal/ChangeSettlementStateModal'
  import tabsDistanceMixin from '@/components/share/mixins/tabsDistanceMixin.js';
  import ChangeStateModal from "../share/ChangeStateModal/ChangeState";

  export default {
    name: 'Finance',
    components: {
      ChangeSettlementStateModal,
      Summary,
      Attachments,
      SettlementComments,
      AddSettlementItemAmountModal,
      FinanceHistory,
      BlockingLoader,
      'SettlementItemsComponentVue': () => import('./SettlementItemsComponent'),
      'SettlementHeader': () => import('./SettlementHeader'),
      RefuseCommentModal,
      AddSettlementCommentModal,
      AddSettlementItemCommentModal,
      AddInvoiceModal,
      Tabs,
      ChangeStateModal
    },
    mixins: [
      ErrorNotify,
      DownloadBlob,
      buttonsMixin,
      tabsMixin,
      eventsMixin,
      modalsMixin,
      tabsDistanceMixin
    ],

    data () {
      return {
        files: null,
        commentModalData: {},
        service: this.$route.meta.acl.service,
        settlement: null,
        settlementItems: null,
        changeStateModalVisible: false,
        task: {
          id: 0
        },
        simpleTransition: false,
        isFormValid: false,
        changeStateVisible: false,
      }
    },
    computed: {
      ...mapState({
        vuexSettlement: state => state.settlementsState.settlement,
        vuexSettlementItems: state => state.settlementsState.items,
        settlementFile: state => state.settlementsState.settlementFile
      }),
      ...mapGetters([
        'changingState',
        'loadingMessage'
      ]),
      allComments () {
        if (this.vuexSettlement && this.vuexSettlement.comments && this.vuexSettlementItems && this.vuexSettlementItems.length !== 0) {
          let comments = []
          this.vuexSettlement.comments.forEach(comment => {
            comment.key = `Settlement-${comment.comment.id}`
            comments.push(comment)})
          this.vuexSettlementItems.forEach((item) => item.data.comments.forEach(comment => {
            comment.number = item.data.number
            comment.key = `SettlementItem-${item.uuid}-comment${comment.comment.id}`
            comments.push(comment)}))
          return comments
        } else {
          return []
        }
      },
      sortedComments () {
        if (this.allComments.length !== 0) {
          let allComments = this.allComments
          return allComments.sort(function (a, b) {
            return moment(a.comment.createdAt) - moment(b.comment.createdAt)
          }).reverse()
        } else {
          return []
        }
      },
      currentlyCommentedItem () {
        if (this.currentlyCommentedItemId && this.vuexSettlementItems.length !== 0) {
          return this.vuexSettlementItems.filter(item => item.data.id === this.currentlyCommentedItemId)[0].data
        } else {
          return {}
        }
      },
      pathForAttachments () {
        return moment.unix(this.vuexSettlement.createdAt.timestamp).format('YYYYMM')
      },
      taskStateForAttachments () {
        return this.vuexSettlement.state === 3 ? {final: true} : {}
      },
      activeTab () {
        return this.tabsData.find(tab => tab[2] === true)[0]
      },
      settlementSentItems () {
        let items = []
        if (this.vuexSettlement) {
          items = this.vuexSettlementItems.filter(item => item.data.state === 2)
          let itemsWithComments = items.filter(item => item.data.comments.length !== 0).sort(this.sortAlphanumerical)
          let itemsWithoutComments = items.filter(item => item.data.comments.length === 0).sort(this.sortAlphanumerical)
          return itemsWithComments.concat(itemsWithoutComments)
        } else {
          return items
        }
      },
      settlementCompletedItems () {
        let items = []
        if (this.vuexSettlement) {
          items = this.vuexSettlementItems.filter(item => item.data.state === 1)
          let itemsWithComments = items.filter(item => item.data.comments.length !== 0).sort(this.sortAlphanumerical)
          let itemsWithoutComments = items.filter(item => item.data.comments.length === 0).sort(this.sortAlphanumerical)
          return itemsWithComments.concat(itemsWithoutComments)
        } else {
          return items
        }
      }
    },
    created () {
      this.getEvents()
    },
    beforeMount () {
      this.getOwnership()
      this.getSettlementData()
    },
    mounted () {
      this.$events.on(this.events.changeState, this.changeState)
      this.$events.on(this.events.simpleTransition, this.doSimpleTransition)
    },
    beforeDestroy() {
      this.clearData()
    },
    methods: {
      ...mapActions([
        'getSettlements',
        'getSettlementItems',
        'sendFiles',
        'changeSettlementState',
        'downloadSettlementFile',
        'getOwnership',
        'clearSettlementData'
      ]),
      sortAlphanumerical: (a, b) =>  a.data.number.localeCompare(b.data.number, 'pl', { numeric: true }),
      clearData () {
        this.clearSettlementData()
      },
      async getSettlementData () {
        try {
          await this.getSettlements(
            { service: this.service, settlementId: this.$route.params.id, reactToStateChange: this.reactToStateChange, $router: this.$router })
          this.vuexSettlement.items.forEach(item => {
            this.getSettlementItemData(item.client.appName, item)
          })
        } catch (e) {
          console.error(e)
          this.errorNotify(e)
        }
      },
      async getSettlementItemData (app, item) {
        try {
          this.getSettlementItems({ app, item })
        } catch (e) {
          console.error(e)
          this.errorNotify(e)
        }
      },
      addSettlementAttachment () {
        this.$refs.file.click()
      },
      async setFiles (event) {
        try {
          this.sendFiles(
            { event, id: this.$route.params.id, service: this.service, notify: this.$notify, events: this.$events })
        } catch (e) {
          this.errorNotify(e)
        }
      },
      async setSettlementState (settlementState) {
        if (settlementState.toString() === '0') {
          this.refuseCommentModalVisibility = true
          return
        }
        if (settlementState.toString() === '2') {
          this.changeStateWithCommentModalVisible = true
          this.changeStateWithCommentTargetState = settlementState
          return
        }
        try {
          await this.changeSettlementState({settlementState, id: this.$route.params.id, service: this.service})
          this.$events.emit('settlement:refresh')
        } catch (e) {
          this.$events.emit('settlement:refresh')
          this.$notify({
            duration: -1,
            type: 'error',
            title: 'Wystąpił błąd',
            text: e.response.data.message
          })
          // this.errorNotify(e)
        }
      },
      async getSettlementFiles (type) {
        let types = {
          confirmation: 'pdf',
          report: 'xls'
        }
        try {
          await this.downloadSettlementFile({type: types[type], id: this.$route.params.id, service: this.service})
          this.downloadBlob(this.base64toBlob(this.settlementFile.value, this.settlementFile.mimeType),
            this.settlementFile.name)
        } catch (e) {
          this.errorNotify(e)
        }
      },
      getEvents () {
        this.events = {
          changeState: `${this.service}:changeState`,
          simpleTransition: `${this.service}:simpleTransition`
        }
      },
      doSimpleTransition () {
        this.simpleTransition = true
      },
      changeState () {
        this.changeStateVisible = true
      },
    }
  }
</script>

