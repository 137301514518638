<template>
  <div
    v-show="!model.hidden"
    ref="root"
    class="attachments--item"
  >
    <div class="attachments--item-select">
      <input
        :id="imgId"
        type="checkbox"
        name="selected"
        :checked="selected"
        @click="toggleItem"
      >
      <label :for="imgId" />
    </div>
    <div class="attachments--thumb">
      <img
        :id="model.id"
        :src="src"
        :class="{'fullSize': model.fullSize, 'thumbnailSize': !model.fullSize}"
        @click.prevent="preview"
        @mouseover="showTooltip"
        @mouseleave="hideTooltip"
      >
    </div>
    <p
      class="attachments--item-name"
      :title="model.filename"
    >
      {{ fileName }}
    </p>
    <span
      v-if="allowedAccess"
      class="attachments--item-remove"
      @click.stop="remove"
    />

    <tooltip
      :class="tooltipClass"
      :model="model"
    />
  </div>
</template>

<script>
import Tooltip from '../../../../../share/tooltip/Tooltip'
import Thumb from '../../../../../share/mixins/Thumb.js'
import Loader from '../../../../../share/Loader'

export default {
  components: {
    Tooltip
  },
  mixins: [
    Thumb,
    Loader
  ],
  props: {
    allowedAccess: { type: Boolean, default () { return true } },
    model: { type: Object, required: true },
    selected: { type: Boolean, required: true }
  },
  data () {
    return {
      loading: false,
      tooltipVisible: false,
      tooltipClass: 'attachment-tooltip',
      service: this.$route.meta.acl.service
    }
  },
  computed: {
    imgId () {
      return `attachment-${this.model.id}`
    }
  },
  watch: {
    selected: function (newVal) {
      let root = this.$refs.root
      if (newVal === false) {
        if (root.classList.contains('item-selected')) {
          root.classList.remove('item-selected')
        }
      } else {
        root.classList.add('item-selected')
      }
    }
  },
  methods: {
    remove () {
      this.$emit('remove', this.model.id)
    },
    toggleItem () {
      let root = this.$refs.root
      if (root.classList.contains('item-selected')) {
        root.classList.remove('item-selected')
      } else {
        root.classList.add('item-selected')
      }
      this.$emit('toggleItem', this.model.id)
    },
    preview (event) {
      if (this.model.mimeType.match(/image.*/)) {
        this.$events.emit('startPreview', event, this.model)
      }
    },
    showTooltip () {
      this.tooltipClass = 'tooltipVisible'
      this.setTooltipPosition()
    },
    hideTooltip () {
      this.tooltipClass = 'attachment-tooltip'
    },
    getItemPosition (element) {
      return this.$el.getBoundingClientRect(element)
    },
    getItemPositionRight () {
      return window.innerWidth - this.getItemPosition().right
    },
    getTooltipWidth () {
      return this.getItemPosition('.attachment-tooltip').width
    },
    setTooltipPosition () {
      if (this.getItemPositionRight() < this.getTooltipWidth()) {
        this.tooltipClass = 'tooltipVisibleRight'
      } else if (this.getItemPositionRight() > this.getTooltipWidth()) {
        this.tooltipClass = 'tooltipVisible'
      }
    }
  }
}
</script>
