<template>
  <li class="category-tag">
    <span>{{ model.name }}</span>
    <a
      class="category-remove"
      @click.prevent="remove"
    ><i class="fa fa-times" /></a>
  </li>
</template>

<script>
export default {
  props: {
    model: { type: Object, required: true }
  },
  methods: {
    remove () {
      this.$emit('remove', this.model.id)
    }
  }
}
</script>
