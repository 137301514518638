<template>
  <div class="attachments">
    <h3 class="tab-header">
      Załączniki
    </h3>
    <div
      v-if="hasAttachments"
      class="row"
    >
      <div style="margin-left: 235px">
        <!--<div v-if="mode == 'add'" class="alert alert-primary">-->
        <InfoBox v-if="false">
          <h4>Lista obsługiwanych formatów załączników</h4>
          <ul>
            <li><label>Dokumenty:</label> doc, docx, rtf, pdf, ods, odt, xls, xlsx, csv, txt, eml, msg, xlsm</li>
            <li><label>Archiwa:</label> 7z, zip, rar, gz, tar</li>
            <li><label>Zdjęcia:</label> bmp, jpeg, jpg, tif, tiff, png, gif</li>
            <li><label>Inne:</label> xml, signPro, SIGNPR, DWG, crw, ath, szf, axx, xades, GPG, pgp, sig, sko</li>
          </ul>
        </InfoBox>
        <list
          v-if="mode === 'view'"
          :active-tab="activeTab"
          :attachments="attachments"
          :service="service"
          :task-id="settlementId"
          :task-number="settlementId"
          :task-type="taskType"
          :task-state="taskState"
          @add="addAttachments"
          @remove="removeAttachment"
        />
        <div
          v-else
          class="content"
        >
          <add-attachments
            :active-tab="activeTab"
            :files="files"
            :file-path="filePath"
            :service="service"
            :task-id="settlementId"
            :task-number="settlementId"
            :task-type="taskType"
            @cancel="viewAttachments"
            @upload="showLoader"
            @uploadFinish="viewAttachments"
            @addFiles="showLoader"
            @addFilesFinish="hideLoader"
            @result="dealWithResult"
          />
        </div>
      </div>
    </div>
    <p
      v-else
      class="content-empty"
    >
      Nie ma jeszcze załączników
    </p>
    <form
      v-show="mode === 'add'"
      ref="form"
      class="attachments--file-form"
      enctype="multipart/form-data"
    >
      <input
        ref="file"
        type="file"
        class="attachments--file-input"
        multiple
        @change="setFiles"
      >
    </form>
    <blocking-loader
      :show="loading"
      :progress="downloadProgress.percentage"
      :type="loaderType"
    >
      {{ loaderMessage }}
    </blocking-loader>
    <Viewer
      :attachments="attachments"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import api from '../../../../../api'
import AddAttachments from './add/List.vue'
import List from './view/List.vue'
import BlockingLoader from '../../../../share/BlockingLoader'
import InfoBox from '../../../../share/InfoText/InfoBox'
import IsImage from './mixins/IsImage'
import Loader from '../../../../share/Loader'
import Viewer from './view/Viewer'
import buttonsStateBlockingMixin from '../../mixins/actionBlockingMixin'

export default {
  components: {
    Viewer,
    InfoBox,
    AddAttachments,
    List,
    BlockingLoader
  },
  mixins: [
    IsImage,
    Loader,
    buttonsStateBlockingMixin
  ],
  props: {
    taskState: { type: Object },
    activeTab: { type: String, required: true },
    path: { type: String, required: true },
    service: { type: String, default () { return '' } },
    settlementId: { required: true },
    taskType: { type: String, default () { return 'main' } }
  },
  data () {
    return {
      events: {
        addAttachments: `${this.service}:${this.taskType}:addAttachments`,
        getFullImage: 'fullImageDownloaded'
      },
      mode: 'view',
      hasAttachments: false,
      attachments: null,
      files: [],
      downloadedFiles: [],
      numberOfFilesToDownload: 0,
      currentlyDownloadedFile: [],
      loading: false,
      loaderMessage: 'Trwa ładowanie załączników. Proszę czekać.',
      loaderType: 'download',
      thirdMenu: []
    }
  },
  computed: {
    currentlyDownloadedFileAmount () {
      return this.currentlyDownloadedFile.length
    },
    filePath: function () {
      if (this.settlementId !== '') {
        return this.path + '/' + _.replace(this.settlementId, '/', '_')
      } else {
        return ''
      }
    },
    filePaths () {
      if (this.settlementId !== '') {
        let filePaths = []
        // add base task first
        filePaths.push(this.path + `/${this.settlementId}`)
        // then add any subtasks
        return filePaths
      } else {
        return []
      }
    },
    canEditAttachments () {
      if (this.settlementId === '') {
        return false
      }
      return true
    },
    downloadProgress () {
      return {
        percentage: Math.ceil((this.currentlyDownloadedFileAmount / (this.numberOfFilesToDownload !== 0 ? this.numberOfFilesToDownload : 1)) * 100) || 0,
        numerical: `Pobieranie: ${this.currentlyDownloadedFileAmount} z ${this.numberOfFilesToDownload} plików.`
      }
    }
  },
  watch: {
    attachments: function (newVal) {
      if (Object.keys(newVal).length === 0 && newVal.constructor === Object) {
        this.hasAttachments = false
      } else {
        this.hasAttachments = true
      }
    },
    activeTab: function (newVal) {
      this.showMenu(newVal)
    },
    currentlyDownloadedFileAmount: function (newVal) {
      if (newVal === this.numberOfFilesToDownload) {
        let filesClone = _.cloneDeep(this.downloadedFiles)
        if (Array.isArray(filesClone)) {
          this.prepareAttachments(filesClone)
        } else {
          this.hideLoader()
          this.currentlyDownloadedFile = []
        }
      }
    }
  },
  created () {
  },
  mounted () {
    this.$events.on(this.events.addAttachments, this.addAttachments)
    this.$events.on(this.events.getFullImage, this.getFullImage)
    this.showMenu(this.activeTab)
    this.loadAttachments()
  },
  destroyed () {
  },
  methods: {
    getTabThirdMenu() {
      let buttons = [
        ...this.getActionBlockade([{
          event: { name: `${this.service}:addSettlementAttachment` },
          icon: 'plus',
          label: 'Dodaj załącznik',
          acl: { action: 'post_settlement_attachments', service: this.service }
        }])
      ]
      this.thirdMenu = buttons
    },
    loadAttachments () {
      if (this.canEditAttachments) {
        // this.showLoader('Trwa pobieranie załączników. Proszę czekać.', 'download')
        this.loading = false
        api.request('storage', 'get', `/download/${this.service}?format=names&filePaths=${this.filePaths.join()}`)
          .then((response) => {
            this.downloadedFiles = []
            this.numberOfFilesToDownload = response.data.length
            if (this.numberOfFilesToDownload !== 0) {
              response.data.forEach((file, index) => this.getSingleAttachment(file, index))
            } else {
              let filesClone = _.cloneDeep(this.downloadedFiles)
              if (Array.isArray(filesClone)) {
                this.prepareAttachments(filesClone)
                this.currentlyDownloadedFile = [1]
                this.numberOfFilesToDownload = 1
              } else {
                this.hideLoader()
                this.currentlyDownloadedFile = []
              }
            }
          })
          .catch((error) => {
            // dont show error notify if access denied
            if (error.response && error.response.status === 403) {
              return
            }
            this.errorNotify(error)
          })
      }
    },
    getSingleAttachment (data, index) {
      if (this.isImage(data.mimeType)) {
        if (this.$store.state.base.attachmentsCache[data.id]) {
          this.downloadedFiles.push({
            id: data.id,
            filename: data.filename,
            createdAt: data.createdAt,
            createdBy: data.createdBy,
            mimeType: data.mimeType,
            contentHash: data.contentHash,
            value: this.$store.state.base.attachmentsCache[data.id].value,
            fullSize: this.$store.state.base.attachmentsCache[data.id].fullSize
          })
          this.currentlyDownloadedFile.push(index)
        } else {
          api.request('storage', 'get', `/download/${this.service}?filter=uuid&filePaths=${data.path}&fileName=${data.storageFilename}`)
            .then((response) => {
              this.downloadedFiles.push({
                id: data.id,
                filename: data.filename,
                createdAt: data.createdAt,
                createdBy: data.createdBy,
                mimeType: data.mimeType,
                contentHash: data.contentHash,
                value: response.data,
                fullSize: false
              })
              this.currentlyDownloadedFile.push(index)
            })
            .catch(() => {
              this.toggleLoading()
              this.$notify({
                type: 'error',
                title: 'Wystąpił błąd',
                text: `Nie można pobrać pliku ${data.filename}`
              })
              this.currentlyDownloadedFile.push(index)
            })
        }
      } else {
        this.downloadedFiles.push({
          id: data.id,
          filename: data.filename,
          createdAt: data.createdAt,
          createdBy: data.createdBy,
          mimeType: data.mimeType,
          contentHash: data.contentHash,
          value: ''
        })
        this.currentlyDownloadedFile.push(index)
      }
    },
    removeAttachment (uuid) {
      Vue.delete(this.attachments, uuid)
    },
    prepareAttachments (data) {
      let list = {}
      data.forEach((el) => {
        el.hidden = false
        list[el.id] = el
      })
      this.attachments = list
      Object.entries(this.attachments).forEach(el => {
        if (this.$store.state.base.attachmentsCache[el[0]]) {
          return
        }
        this.$store.commit('SET_ATTACHMENTS_CACHE', el)
      })
    },
    formatDate (dateVal, withTime = false) {
      const date = new Date(dateVal)
      let formatedDate = date.toLocaleDateString('pl-PL')
      if (withTime) {
        formatedDate += ' @ ' + date.toLocaleTimeString('pl-PL').substr(0, 5)
      }
      return formatedDate
    },
    addAttachments () {
      this.mode = 'add'
      if (!this.hasAttachments) {
        this.createPath()
      }
      this.$refs.file.click()
    },
    setFiles (event) {
      let files = event.target.files || event.dataTransfer.files
      if (!files.length) {
        return
      }
      this.files = files
    },
    viewAttachments (uploaded = false) {
      this.mode = 'view'
      if (uploaded) {
        this.showLoader('Trwa aktualizacja listy załączników. Proszę czekać.')
        this.loadAttachments()
      } else if (Object.keys(this.attachments).length === 0) {
        this.hasAttachments = false
      }
      this.files = []
      this.$refs.file.value = ''
      this.reRegisterAddEvent()
      this.showMenu(this.activeTab)
    },
    showMenu (tab) {
      this.getTabThirdMenu()

      if (!this.hasAttachments && this.canEditAttachments && tab === 'attachments' && !this.taskState.final) {
        this.$events.$emit('dashboard:menu:taskTab', this.thirdMenu)
      }
    },
    showLoader (message, type) {
      if (message !== undefined) {
        this.loaderMessage = message
      }
      if (type !== undefined) {
        this.loaderType = type
      }
      this.loading = true
    },
    hideLoader () {
      this.$nextTick(() => {
        this.loading = false
        this.loaderMessage = ''
      })
    },
    reRegisterAddEvent () {
      this.$events.off(this.events.addAttachments, this.addAttachments)
      this.$events.on(this.events.addAttachments, this.addAttachments)
    },
    createPath () {
      this.hasAttachments = true
    },
    dealWithResult (failures) {
        this.viewAttachments(true)
    },
    showUploadInfo (failures) {
      if (failures.length > 0) {
        let message = '<p>Przy zapisie następujących załączników wystąpił błąd:</p>'
        message += '<ul class="notification--message-list">'
        for (let file of failures) {
          message += `<li>${file.filename}</li>`
        }
        message += '</ul>'

        this.$notify({
          type: 'error',
          text: message,
          duration: -1
        })
      }
    },
    getFullImage (event) {
      event.fullSize = true
      this.$store.commit('SET_ATTACHMENT_FULLSIZE', event)
      this.attachments[event.id].value = event.value
      this.attachments[event.id]['fullSize'] = true
    }
  }
}
</script>
