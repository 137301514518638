<template>
  <div class="box box-component box-info">
    <p class="tooltip-name">
      <strong>{{this.$t('tooltip.name')}}:</strong> {{ model.filename }}
    </p>
    <p><strong>{{$t('tooltip.author')}}:</strong> {{ model.createdBy }}</p>
    <p><strong>{{$t('tooltip.added')}}:</strong> {{ createdAt }}</p>
    <p v-if="categories.length !== 0">
      <strong>{{$t('tooltip.categories')}}:</strong>
    </p>
    <ul
      v-if="categories"
      class="attachments--item--category-list"
    >
      <li
        v-for="(category, index) in categories"
        :key="index"
        class="filter"
      >
        <span>{{ category }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    model: {type: Object, default: () => {}},
    categories: {type: Array, default: () => []}
  },
  computed: {
    createdAt () {
      return moment(this.model.createdAt).format('DD.MM.YYYY @ kk:mm')
    }
  }
}
</script>
<style scoped>
    .tooltip-name {
        word-break: break-word;
    }
</style>
