export default {
  data () {
    return {
      itemCommentModalVisible: false,
      itemAmountModalVisible: false,
      settlementCommentModalVisible: false,
      refuseCommentModalVisibility: false,
      currentlyCommentedItemId: null,
      addInvoiceModalVisibility: false,
      currentlyEditedAmountItem: {},
      changeStateWithCommentModalVisible: false,
      changeStateWithCommentTargetState: ''
    }
  },
  methods: {
    $addSettlementItemComment ({ id, comment, number, comments }) {

      this.currentlyCommentedItemId = id
      this.itemCommentModalVisible = true
    },
    $editSettlementItemAmount ({ id, amount }) {
      this.currentlyEditedAmountItem = { id, amount }
      this.itemAmountModalVisible = true
    },
    '$closeCommentModal' () {
      this.currentlyCommentedItemId = null
      this.itemCommentModalVisible = false
    },
    $addSettlementComment () {
      this.settlementCommentModalVisible = true
    },
    '$closeSettlementCommentModal' () {
      this.settlementCommentModalVisible = false
    },
    '$closeRefuseCommentModal' () {
      this.refuseCommentModalVisibility = false
    },
    '$addInvoiceDataModal' () {
      this.addInvoiceModalVisibility = true
    },
    '$closeInvoiceModal' () {
      this.addInvoiceModalVisibility = false
    },
    $closeChangeStateWithCommentModal () {
      this.changeStateWithCommentModalVisible = false
      this.changeStateWithCommentTargetState = ''
    }
  }
}
