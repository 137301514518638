<template>
  <div>
    <base-form-modal
      id="change-state-modal"
      :show="show"
      :title="title"
      :ok-text="okText"
      :cancel-text="cancelText"
      :remember-model-after-close="true"
      @show="open"
      @close="handleCloseChangeStateModal"
      @beforeSubmit="bool => beforeSubmitTrigger = bool"
      :class="{'change-state-modal-invisible': simpleTransition}"
    >
      <form-modal
        ref="form"
        v-model="model"
        :is-before-submit="beforeSubmitTrigger"
        @changeTransition="handleChangeTransition"
        :transitions="this.$store.state.base.workflow.transitions"
        :transitions-loaded="transitionsLoaded"
      />
    </base-form-modal>
    <blocking-loader :show="loading">
      {{ loaderMessage }}
    </blocking-loader>
    <blocking-loader :show="m$loadingAttachments" :progress="Math.floor((m$aggregator/m$allFiles) * 100)"
                     type="download">
      Trwa ładowanie plików. Proszę czekać.
    </blocking-loader>
  </div>
</template>

<script>
import BaseFormModal from '../../../../share/modal/BaseFormModal.vue'
import BlockingLoader from '../../../../share/BlockingLoader.vue'
import FormModalMixin from '../../../../share/modal/FormModalMixin.js'
import FormModal from './form/ChangeState.vue'
import api from '../../../../../api'
import ErrorNotify from '../../../../share/mixins/ErrorNotify.js'
import {mapActions} from 'vuex'
import WebStorage from '../../../../share/WebStorage.js'
import fileMixin from './mixins/fileMixin.js'

export default {
  components: {
    BlockingLoader,
    FormModal,
    BaseFormModal
  },
  mixins: [
    FormModalMixin,
    ErrorNotify,
    fileMixin
  ],
  props: {
    changeStateEvent: {type: String, required: true},
    taskId: {required: true},
    service: {type: String, required: true},
    filePath: {type: String}
  },
  data () {
    return {
      simpleTransition: false,
      title: 'Przejdź dalej',
      okText: 'Zmień status',
      loading: false,
      loaderMessage: 'Trwa zapisywanie. Proszę czekać.',
      saveAllowed: false,
      transitionId: null,
      showUserSelect: false,
      transitionsLoaded: false,
      model: {},
    }
  },
  watch: {
    show (newVal) {
      if (newVal) {
        if (this.$store.state.base.workflow.transitions.length === 0) {
          return
        }
        if (this.$store.state.base.workflow.transitions.length > 1) {
          return
        }
        this.loading = true
        this.simpleTransition = true
        this.submitForm()
      } else {
        this.$validator.reset()
      }
    }
  },
  computed: {
    isCancellation () {
        return (
          this.$store.getters.getCurrentSchemaModelReference.communication !== undefined
          && this.$store.getters.getCurrentSchemaModelReference.communication !== null
          && this.$store.getters.getCurrentSchemaModelReference.communication.isCancellation === true
        )
    },
  },
  mounted () {
    this.loadStates()
  },
  methods: {
    ...mapActions({
      getIncompleteTasks: 'getIncompleteTasks'
    }),
    handleChangeTransition (event) {
      this.model = { selectedTransitionName: event }
    },
    handleCloseChangeStateModal () {
      this.transitionId = null
      this.$emit('close')
    },
    callIncompleteTasksMethod () {
      let contractors = {}
      Object.entries(WebStorage.getSecurityActions()).filter(([contractorKey, contractorValue], index) => contractorValue.length !== 0 && (contractorKey === 'intgen' || contractorKey === 'intbls')).forEach(([contractorKey, contractorValue], index) => contractors[contractorKey] = contractorValue)
      this.getIncompleteTasks({services: contractors})
    },
    getNextStateMethod () {
      return api.request('finance', 'put', `/settlements/${this.taskId}`, this.createDataToSend())
    },
    nextState () {
      if (!this.isDataValid()) {
        this.loading = false
        return
      }

      this.getNextStateMethod()
        .then((response) => {
          if (response.data.hasOwnProperty('message') && response.data.message.includes('error:')) {
            this.$notify({
              type: 'info',
              text: response.data.message.split('error:')[1]
            })
            this.loading = false
            return
          }

          this.$emit('close')
          this.$events.emit('task:changedState')
          this.callIncompleteTasksMethod()
          if (this.simpleTransition) {
            this.simpleTransition = false
          }
          this.loading = false
          this.$notify({
            type: 'success',
            text: 'Zmieniono status'
          })
        })
        .catch((error) => {
          this.$emit('close')
          this.loading = false
          if (this.simpleTransition) {
            this.simpleTransition = false
          }
          this.errorNotify(error, 'error', error.message, '', -1)
        })
    },
    submitForm () {
      this.nextState()
      if (!this.isFormValid && !this.skipValidation()) {
        if (this.isDataValid()) {
          this.$emit('close-show-form-errors')
        }
        this.loading = false
        return
      }

      if (this.saveAllowed) {
        this.loading = true
        this.nextState()
      }
    },
    skipValidation() {
      let transition = this.$store.state.base.workflow.transitions.find(el => el.value === parseInt(this.model.selectedTransitionName))
      if (transition.metadata && transition.metadata.validations) {
        return false
      }
      return true
    },
    convertStringToBool(str){
        return ((str === "True") || (str === "true")) ? true : false},

    createDataToSend () {
      let args = {}
      let data = {
        transitionId: this.model.selectedTransitionName,
        invoice: this.model.invoice
      }

      if (args) {
        data = {
          ...data,
          ...args
        }
      }

      if (this.$isWithClients(this.service)) {
        data['nextState'] = true
      }
      return data
    },
    isDataValid () {
      this.vErrors.items.forEach(err => {
        this.$notify({
          type: 'error',
          title: '',
          text: err.msg
        })
      })

      if (this.vErrors.items.length > 0) {
        return false
      }

      return true
    },
    getTransitions () {
      return api.request(this.service, 'get', `workflows/1/settlements/${this.taskId}/transitions`)
    },
    loadStates () {
      this.transitionsLoaded = false
      this.getTransitions()
        .then((response) => {
          let storeTransitions = []
          const transitions = response.data

          storeTransitions = transitions.map(transition => {
            return {
              label: transition.label,
              value: transition.id,
              name: transition.name,
              metadata: transition.metadata,
              events: transition.transition_events
            }
          })

          this.$store.commit('SET_WORKFLOW_TRANSITIONS', storeTransitions)

          if (this.$store.state.base.workflow.transitions.length === 1) {
            this.handleChangeTransition(this.$store.state.base.workflow.transitions[0].value)
          }

          this.transitionsLoaded = true
        })
        .catch((e) => {
          console.error(e)
          // TODO: distinguish between error and last possible transition
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można załadować stanów'
          })
        })
    }
  }
}
</script>
<style>
#change-state-modal.change-state-modal-invisible {
  opacity: 0;
}
</style>
