<template>
  <div class="summary">
    <div class="clients-summary grid-container">
      <div v-for="clientSummary in summary" :key="clientSummary.name" class="grid-item">
        <div
          class="settlement-summary"
        >
          <div v-if="vuexSettlement && vuexSettlement.createdBy.vat">
            <p><strong>Nazwa:</strong> {{ clientSummary.name }}</p>
            <p><strong>Suma netto:</strong> {{ clientSummary.netSum }} zł</p>
            <p><strong>VAT:</strong> {{ vuexSettlement.createdBy.vat.label }}</p>
            <p><strong>Suma brutto:</strong> {{ clientSummary.grossSum }} zł</p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="grid-item overall-settlement-summary"
    >
      <div>
        <p><strong>Suma netto:</strong> {{ (vuexSettlement.netSum).toFixed(2) }} zł</p>
        <p v-if="vuexSettlement && vuexSettlement.createdBy && vuexSettlement.createdBy.vat">
          <strong>
            VAT:
          </strong>
          {{ vuexSettlement.createdBy.vat.label }} zł
        </p>
        <p><strong>Suma brutto:</strong> {{ (vuexSettlement.grossSum).toFixed(2) }} zł</p>
      </div>
      <p class="summary-description">
        Sumy netto i brutto wyliczone na podstawie zaakceptowanych zleceń za dany okres rozliczeniowy oraz oreślonej stawki VAT.
      </p>
    </div>
  </div>
</template>

<script>
import api from '../../../../../api'
export default {
  name: 'Summary',
  data() {
    return {
      summary: []
    }
  },
  props: {
    vuexSettlement: {type: Object, default: () => {}}
  },
  mounted () {
    this.getSettlementClientsSummary()
  },
  computed: {
    isAllowedToShowSummary () {
      return this.$store.state.base.securityActions['finance'].includes('get_settlement_summary')
    }
  },
  methods: {
    async getSettlementClientsSummary () {
      if (!this.isAllowedToShowSummary) {
        this.summary = []
        return
      }
      try {
        let result = await api.request('finance', 'get', `/settlements/${this.vuexSettlement.uuid}/summary`)
        this.summary = result.data
      } catch (e) {
        this.summary = []
        console.error(e)
      }
    },
    round (n, k) {
      let factor = Math.pow(10, k)
      return Math.round(n * factor) / factor
    }
  }
}
</script>

<style scoped>

.summary-description {
  font-size: 12px;
  font-style: italic;
  word-break: break-word;
  max-width: 10vw;
}
.overall-settlement-summary {
  padding: 20px 60px;
  width: 25%;
  float: left;
}
.clients-summary {
  width: 75%;
}
.summary {
  display: flex;
}
.settlement-summary > div {
  margin: 20px 0;
  padding-left: 15px;
  padding-right: 15px;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto;
}

.grid-item {
  border: #e5e2eb solid 1px;
  border-top: #5bc0de 3px solid;
  text-align: left;
}
</style>
