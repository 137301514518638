<template>
  <div>
    <input-type
      v-model="invoice['number']"
      @input="handleChange"
      v-validate.initial="'required'"
      :required="true"
      :name="'additional-component-invoice'"
      :error-message="!invoice['number'] ? 'Numer faktury jest wymagany' : undefined"
      :label="'Numer faktury'"
    />
    <date-picker-type
      v-validate.initial="'required'"
      v-model="invoice['date']"
      name="invoiceDate"
      label="Data faktury"
      :required="true"
      :error-message="!invoice['date'] ? 'Data faktury jest wymagana' : undefined"
    />
  </div>
</template>

<script>
import DatePickerType from '@/components/share/form/type/DatePickerType.vue'
import InputType from '@/components/share/form/type/InputType.vue'

export default {
  name: 'AdditionalComponentsInvoice',
  props: {
    value: {
      type: Object, required: false, default: () => {
      }
    }
  },
  components: {InputType, DatePickerType},
  data () {
    return {
      invoice: {
        number: undefined,
        date: undefined
      }
    }
  },
  mounted () {
    this.invoice = {
      number: this.value && this.value.number || undefined,
      date: this.value && this.value.date || undefined
    }
    this.handleChange()
  },
  beforeDestroy () {
    this.$emit('input', undefined)
  },
  methods: {
    handleChange () {
      this.$emit('input', this.invoice)
    }
  }
}
</script>

<style scoped>

</style>
