<template>
  <form>
    <input-type
      v-validate="{ rules: { required: true} }"
      :name="'Numer faktury'"
      :label="'Numer faktury'"
      :error-message="vErrors.first('Numer faktury')"
      :required="true"
      v-model="value.invoiceNumber"
    />
    <date-picker-type
      :name="'Data faktury'"
      :label="'Data faktury'"
      v-model="value.invoiceDate"
      :error-message="vErrors.first('Data faktury')"
      :required="true"
      :max-date="maxDate"
      v-validate="{ rules: { required: true, date_format: 'yyyy-MM-dd'} }"
    />
  </form>
</template>

<script>
import FormModalValidation from '../../../../../share/form/FormModalValidation'
import moment from 'moment'
import DatePickerType from '../../../../../share/form/type/DatePickerType'
import InputType from '../../../../../share/form/type/InputType'

export default {
  components: { InputType, DatePickerType },
  mixins: [
    FormModalValidation
  ],
  props: {
    value: {type: Object, default: () => {}}
  },
  data() {
    return {
    }
  },
  mounted () {
  },
  computed: {
    maxDate () {
      return moment().add(1,'day').format('YYYY-MM-DD')
    }
  },
  methods: {
    handleDatepickerUpdate (...e) {
      this.$emit('field-updated', e)
    }
  }
}
</script>
