export default {
  beforeMount() {
    this.$getTabs()
  },
  data () {
    return {
      tabsData: []
    }
  },
  methods: {
    $getTabs () {
      this.tabsData = [
        [
          'settlement',
          'Rozliczenie',
          true,
          ['finance.get_settlement']
        ],
        [
          'history',
          'Historia',
          false,
          ['history.get_subject_changes']
        ],
        [
          'comments',
          'Komentarze',
          false,
          ['finance.get_settlement']
        ],
        [
          'attachments',
          'Załączniki',
          false,
          ['finance.get_settlement']
        ]
      ]
    },
    $changeTab (tabName) {
      this.tabsData = this.tabsData.map(tab => tab[0] === tabName ? (tab.map((tabProp, index) => index === 2 ? (tabProp = true) : tabProp)) : (tab.map((tabProp, index) => index === 2 ? (tabProp = false) : tabProp)))
      if (this.activeTab === 'settlement') {
        this.reactToStateChange(this.vuexSettlement.state)
      }
    }
  }
}
