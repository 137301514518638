<template>
  <div
    v-show="!model.hidden"
    ref="root"
    class="attachments--item-add"
    @drop.prevent="addCategory"
    @dragover.prevent
  >
    <div class="attachments--thumb">
      <img :src="src">
    </div>
    <p
      class="attachments--item-name"
      :title="model.filename"
    >
      {{ fileName }}
    </p>
    <ul class="attachments--item--category-list">
      <category
        v-for="(category, id) in categories"
        :key="id"
        :model="category"
        @remove="removeCategory"
      />
    </ul>
    <span
      v-if="allowedAccess"
      class="attachments--item-remove"
      @click.stop="remove"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import Category from './Category'
import Thumb from '../../../../../share/mixins/Thumb.js'

export default {
  components: {
    Category
  },
  mixins: [
    Thumb
  ],
  props: {
    allowedAccess: { type: Boolean, default () { return true } },
    model: { type: Object, required: true },
    allCategories: { type: Object, required: true }
  },
  data () {
    return {
      events: {
        addDropboxCategory: `bundle:dropbox:attachments`
      },
      categories: {}
    }
  },
  mounted () {
    this.$events.on(this.events.addDropboxCategory, this.addDropboxCategory)
  },
  methods: {
    remove () {
      this.$emit('remove', this.model.id)
    },
    addCategory (event) {
      const categoryId = Number(event.dataTransfer.getData('text'))

      Vue.set(this.categories, categoryId, this.allCategories[categoryId])
      this.updateCategories()
    },
    addDropboxCategory (data) {
      if (data.subject === 'category') {
        const categoryId = Number(data.value)
        Vue.set(this.categories, categoryId, this.allCategories[categoryId])
        this.updateCategories()
      }
    },
    removeCategory (id) {
      Vue.delete(this.categories, id)
      this.updateCategories()
    },
    updateCategories () {
      const categories = {
        id: this.model.id,
        categories: this.categories
      }
      this.$emit('updateCategories', categories)
    }
  }
}
</script>
