<template>
  <base-form-modal
    :show="show"
    :title="'Dodaj komentarz do zlecenia'"
    :ok-text="okText"
    :cancel-text="cancelText"
    @show="open"
    @close="$emit('close')"
    @beforeSubmit="bool => beforeSubmitTrigger = bool"
  >
    <form-modal
      ref="form"
      v-model="comment"
      :currently-commented-item="currentlyCommentedItem"
      :is-before-submit="beforeSubmitTrigger"
    />
  </base-form-modal>
</template>

<script>
import BaseFormModal from '../../../../../share/modal/BaseFormModal'
import FormModalMixin from '../../../../../share/modal/FormModalMixin'
import FormModal from './AddSettlementItemCommentForm'
import ValidatorReset from '../../../../../share/mixins/ValidatorReset'
import {mapActions} from 'vuex'

export default {
  components: {
    FormModal,
    BaseFormModal
  },
  mixins: [
    FormModalMixin,
    ValidatorReset
  ],
  props: {
    service: { type: String, default: () =>  '' },
    currentlyCommentedItem: {
      type: Object, default: () => ({})
    },
  },
  data () {
    return {
      comment: {comment: ''}
    }
  },
  mounted () {
  },
  methods: {
    ...mapActions([
      'sendSettlementItemComment'
    ]),
    async submitForm () {
      try {
        await this.sendSettlementItemComment({id: this.currentlyCommentedItem.id, comment: this.comment.comment, service: this.service, number: this.currentlyCommentedItem.number})
        this.comment.comment = ''
        this.$emit('success')
        this.$emit('close')
        this.$events.emit('settlement:refresh:comment')
      } catch (e) {
        this.$emit('close')
        this.$events.emit('settlement:refresh')
        this.errorNotify(e)
      }
    }
  }
}
</script>
