<template>
  <div class="attachments--list">
    <item
      v-for="attachment in attachments"
      :key="attachment.id"
      :model="attachment"
      :all-categories="categories"
      @remove="removeItem"
      @updateCategories="updateItemCategories"
    />
  </div>
</template>

<script>
import api from '../../../../../../api'
import Vue from 'vue'
import Item from './Item.vue'
import File from '../mixins/File.js'

export default {
  components: {
    Item
  },
  mixins: [
    File
  ],
  props: {
    activeTab: { type: String, required: true },
    categories: { type: Object, default () { return {} } },
    files: { type: [FileList, Array] },
    filePath: { type: String, required: true },
    service: { type: String, required: true, default () { return '' } },
    taskId: { type: Number, default () { return 0 } },
    taskNumber: { type: String, required: true },
    taskType: { type: String, default () { return 'main' } }
  },
  data () {
    return {
      events: {
        upload: `${this.service}:${this.taskType}:uploadAttachments`,
        cancel: `${this.service}:${this.taskType}:cancelAddAttachments`
      },
      sending: false,
      attachments: {},
      numberOfFilesAdded: 0,
      fieldCounter: 0,
      categoriesCounter: 0,
      attachmentsCategories: {},
      form: null,
      forms: {},
      thirdMenu: [
        {
          event: { name: `${this.service}:${this.taskType}:uploadAttachments` },
          icon: 'save',
          label: 'Zapisz',
          acl: { security: false }
        },
        {
          event: { name: `${this.service}:${this.taskType}:cancelAddAttachments` },
          icon: 'delete',
          label: 'Anuluj',
          acl: { security: false }
        }
      ]
    }
  },
  computed: {
    url: function () {
      return '/upload/' + this.service
    }
  },
  watch: {
    files: function (newVal) {
      if (newVal.length > 0) {
        this.numberOfFilesAdded = newVal.length
        this.$emit('addFiles', 'Trwa dodawanie załączników. Proszę czekać.', 'add')
        this.parseFormFiles(newVal)
      }
    },
    activeTab: function (newVal) {
      this.showMenu(newVal)
    },
    categoriesCounter: function (newVal) {
      if (newVal === this.fieldCounter) {
        this.returnResult()
      }
    }
  },
  mounted () {
    this.$events.on(this.events.upload, this.handleUpload)
    this.$events.on(this.events.cancel, this.cancel)

    this.showMenu(this.activeTab)
  },
  destroyed  () {
    this.fieldCounter = 0
    this.categoriesCounter = 0
    this.numberOfFilesAdded = 0
  },
  methods: {
    handleUpload () {
      if (this.sending === false) {
        this.prepareForm()
      }
    },
    cancel () {
      this.$emit('cancel')
    },
    formatDate (dateVal, withTime = false) {
      const date = new Date(dateVal)
      let formatedDate = date.toLocaleDateString('pl-PL')
      if (withTime) {
        formatedDate += ' @ ' + date.toLocaleTimeString('pl-PL').substr(0, 5)
      }
      return formatedDate
    },
    removeItem (id) {
      Vue.delete(this.attachments, id)
      Vue.delete(this.attachmentsCategories, id)
    },
    updateItemCategories (categories) {
      Vue.set(this.attachmentsCategories, categories.id, categories.categories)
    },
    uploadAssignedCategories (uuid, skip = false) {
      if (skip) {
        this.forms[uuid].result = 'failure'
        this.categoriesCounter += 1
        return
      }

      let data = this.prepareCategoriesToSend(uuid)

      if (data === null) {
        this.forms[uuid].result = 'success'
        this.categoriesCounter += 1
        return
      }

      api.request(this.service, 'post', `/attachment-categories`, data)
        .then((response) => {
          this.$notify({
            type: 'success',
            text: 'Pliki zostały pomyślnie zapisane'
          })
          this.forms[uuid].result = 'success'
          this.categoriesCounter += 1
        })
        .catch((error) => {
          this.forms[uuid].result = 'failure'
          this.categoriesCounter += 1
          console.error(error)
        })
    },
    prepareCategoriesToSend (uuid) {
      let obj = {
        uuid,
        task: this.taskId,
        categories: Object.keys(this.attachmentsCategories[uuid] || {}) || []
      }
      return obj
    },
    showMenu (tab) {
      if (tab === 'attachments') {
        this.$events.$emit('dashboard:menu:taskTab', this.thirdMenu)
      }
    },
    returnResult () {
      let uuids = Object.keys(this.attachments)
      let failures = []
      uuids.forEach((uuid) => {
        if (this.forms[uuid].result === 'failure') {
          failures.push(this.attachments[uuid])
        }
      })
      this.$emit('result', failures)
    }
  }
}
</script>
