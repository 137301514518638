<template>
  <base-form-modal
    :show="show"
    :title="'Dodaj komentarz do zlecenia'"
    :ok-text="okText"
    :cancel-text="cancelText"
    @show="open"
    @close="$emit('close')"
    @beforeSubmit="bool => beforeSubmitTrigger = bool"
  >
    <form-modal
      ref="form"
      v-model="currentlyEditedAmountItem"
      :is-before-submit="beforeSubmitTrigger"
    />
  </base-form-modal>
</template>

<script>
import BaseFormModal from '../../../../../share/modal/BaseFormModal'
import FormModalMixin from '../../../../../share/modal/FormModalMixin'
import FormModal from './AddSettlementItemAmountForm'
import ValidatorReset from '../../../../../share/mixins/ValidatorReset'
import {mapActions} from 'vuex'

export default {
  components: {
    FormModal,
    BaseFormModal
  },
  mixins: [
    FormModalMixin,
    ValidatorReset
  ],
  props: {
    service: { type: String, default: () =>  '' },
    currentlyEditedAmountItem: {
      type: Object, default: () => ({})
    }
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    ...mapActions([
      'sendSettlementItemAmount'
    ]),
    async submitForm () {
      try {
        await this.sendSettlementItemAmount({id: this.currentlyEditedAmountItem.id, amount: this.currentlyEditedAmountItem.amount, service: this.service})
        this.$emit('success')
        this.$emit('close')
        this.$events.emit('settlement:refresh')
      } catch (e) {
        this.$events.emit('settlement:refresh')
        this.errorNotify(e)
      }
    }
  }
}
</script>
