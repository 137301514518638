<template>
  <blocking-loader
    v-if="model"
    :show="loading"
  >
    Pobieranie podglądu
  </blocking-loader>
</template>
<script>
import Viewer from 'viewerjs'
import api from '../../../../../../api'
import BlockingLoader from '../../../../../share/BlockingLoader'
import Thumb from '../../../../../share/mixins/Thumb.js'
import Loader from '../../../../../share/Loader'

export default {
  name: 'Viewer',
  components: { BlockingLoader },
  mixins: [
    Loader,
    Thumb
  ],
  props: {
    attachments: {type: Object, default: () => {}}
  },
  data () {
    return {
      events: {
        startPreview: 'startPreview'
      },
      viewer: null,
      viewerOptions: {
        url: 'src',
        navbar: false,
        toolbar: {
          zoomIn: true,
          zoomOut: true,
          oneToOne: false,
          reset: true,
          prev: true,
          next: true,
          play: {
            show: false
          },
          rotateLeft: true,
          rotateRight: true,
          flipHorizontal: true,
          flipVertical: true
        }
      },
      viewerAttachments: null,
      imagesForViewer: null,
      model: null,
      loading: false
    }
  },
  computed: {
  },
  watch: {
    attachments: function (newVal) {
      if (newVal) {
        this.viewerAttachments = newVal
      }
    }
  },
  mounted () {
    this.$events.on(this.events.startPreview, this.preview)
  },
  methods: {
    getImagesForViewer () {
      if (this.viewerAttachments !== null && Object.keys(this.viewerAttachments).length !== 0) {
        const sortAlphaNum = (a, b) => a.filename.localeCompare(b.filename, 'pl', { numeric: true })
        let sorted = Object.values(this.viewerAttachments).sort(sortAlphaNum)
        let div = []
        div = document.createElement('div')
        sorted.forEach(att => {
          let img = document.createElement('img')
          img.src = ''
          img.src = this.getNewSrc(att)
          img.id = att.id
          img.className = att.fullSize ? 'fullSize' : 'thumbnailSize'
          div.appendChild(img)
        })
        this.imagesForViewer = []
        this.imagesForViewer = div
        return this.imagesForViewer
      }
    },
    preview (event, model) {
      this.model = model
      if (this.model.mimeType.match(/image.*/)) {
        if (this.model.hasOwnProperty('fullSize') && this.model.fullSize === true) {
          let element = this.getImagesForViewer()
          this.destroyOnClose(element)
          this.createViewer(element)
          this.handleViewChange(element, true)
          this.viewer.show()
          this.viewer.view(this.getImagesViewerList(event, element).imageIndex)
          this.viewer.update()
        } else {
          if (this.loading === false) {
            this.loading = true
            this.getFullAttachment(event)
          }
        }
      }
    },
    getFullAttachment (event) {
      api.request('storage', 'get', `/download/${this.$route.meta.acl.service}?filter=uuids&fileUuids=${this.model.id}`)
        .then((response) => {
          this.$events.$emit('fullImageDownloaded', {
            id: this.model.id,
            value: response.data.value
          })
          let attachments = this.viewerAttachments
          attachments[this.model.id].value = response.data.value
          attachments[this.model.id]['fullSize'] = true
          this.viewerAttachments = attachments
          let element = this.getImagesForViewer()
          this.destroyOnClose(element)
          this.createViewer(element)
          this.viewer.show()
          this.viewer.view(this.getImagesViewerList(event, element).imageIndex)
          this.handleViewChange(element, true)
          this.loading = false
          this.viewer.update()
        })
        .catch((e) => {
          console.log(e)
          this.loading = false
          this.toggleLoading()
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: `Nie można pobrać pliku ${this.model.filename}`
          })
        })
    },
    getImagesViewerList (event, imagesContainer) {
      let images = imagesContainer.children
      let imageIndex = Array.from(images).map((el, index) => {
        if (el.id === event.target.id) {
          return index
        }
      }).filter(el => el)
      return {
        images,
        imageIndex
      }
    },
    createViewer (el) {
      this.viewer && this.viewer.destroy()
      this.viewer = new Viewer(el, this.viewerOptions)
    },
    handleViewChange (el, restart) {
      let start = function (el) {
        el.addEventListener('view', handleEvent)
      }
      let stop = function (el) {
        el.removeEventListener('view', handleEvent)
      }
      let handleEvent = (event) => {
        if (event.detail.originalImage.className === 'thumbnailSize') {
          this.loading = true
          this.viewer.hide()
          this.viewer.destroy()
          api.request('storage', 'get', `/download/${this.$route.meta.acl.service}?filter=uuids&fileUuids=${event.detail.originalImage.id}`)
            .then((response) => {
              this.$events.$emit('fullImageDownloaded', {
                id: event.detail.originalImage.id,
                value: response.data.value
              })
              let attachments = this.viewerAttachments
              this.viewerAttachments = null
              attachments[event.detail.originalImage.id].value = response.data.value
              attachments[event.detail.originalImage.id]['fullSize'] = true
              this.viewerAttachments = attachments
              this.preview({ target: { id: event.detail.originalImage.id } }, this.model)
              this.loading = false
            })
            .catch((e) => {
              console.log(e)
              this.loading = false
              this.toggleLoading()
              this.$notify({
                type: 'error',
                title: 'Wystąpił błąd',
                text: `Nie można pobrać pliku`
              })
            })
        }
      }
      if (restart) {
        stop(el)
        start(el)
      } else {
        stop(el)
      }
    },
    destroyOnClose (el) {
      el.addEventListener('hidden', () => {
        this.viewer && this.viewer.destroy()
        el = []
      })
    }
  }
}
</script>
