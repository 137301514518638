<template>
  <base-form-modal
    :show="show"
    :title="title"
    :ok-text="okText"
    :cancel-text="cancelText"
    @show="open"
    @close="$emit('close')"
    @beforeSubmit="bool => beforeSubmitTrigger = bool"
  >
    <form-modal
      ref="form"
      v-model="settlement"
      :is-before-submit="beforeSubmitTrigger"
    />
  </base-form-modal>
</template>

<script>
  import BaseFormModal from '../../../../../share/modal/BaseFormModal'
  import FormModalMixin from '../../../../../share/modal/FormModalMixin'
  import FormModal from './AddSettlementCommentForm'
  import ValidatorReset from '../../../../../share/mixins/ValidatorReset'
  import { mapActions } from 'vuex'

  export default {
    components: {
      FormModal,
      BaseFormModal
    },
    mixins: [
      FormModalMixin,
      ValidatorReset
    ],
    props: {
      service: { type: String, default: '' },
      settlement: { type: Object, default: () => ({}) }
    },
    data () {
      return {
        title: 'Dodaj komentarz do rozliczenia'
      }
    },
    mounted () {
    },
    methods: {
      ...mapActions([
        'sendSettlementComment'
      ]),
      async submitForm () {
        try {
          await this.sendSettlementComment({
            service: this.service,
            id: this.$route.params.id,
            comment: this.settlement.comment
          })
          this.$emit('success')
          this.$emit('close')
          this.$events.emit('settlement:refresh')
        } catch (e) {
          this.$events.emit('settlement:refresh')
          this.errorNotify(e)
        }
      }
    }
  }
</script>
