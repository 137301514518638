import api from '../../../../../../api'
import EXIF from 'exif-js'
import ExifRestore from '../modules/ExifRestorer.js'
import {
  TARGET_VIEW,
  TARGET_FORM,
  RESIZE_QUALITY,
  DEFAULT_WIDTH
} from '../constants/constants.js'
import IsImage from './IsImage'

export default {
  mixins: [
    IsImage
  ],
  methods: {
    addFile (file, fileValue) {
      let uuid = this.uuidv4()
      let attachment = {
        id: uuid,
        filename: file.name,
        mimeType: file.type,
        value: fileValue
      }

      this.$set(this.attachments, uuid, attachment)
      if (Object.keys(this.attachments).length === this.numberOfFilesAdded) {
        this.$emit('addFilesFinish')
      }
    },
    parseFormFiles (files) {
      for (let file of files) {
        this.prepareFile(file, this.addFile)
      }
    },
    addFileToForm (file, value) {
      let name = file.uuid + '_' + file.name
      let data = this.b64toBlob(value, file.type)
      this.forms[file.uuid].form.append(`file[${this.fieldCounter}]`, data, name)

      this.upload(file.uuid)
    },
    prepareForm () {
      this.$emit('upload', 'Trwa zapisywanie załączników. Proszę czekać.', 'upload')
      this.sending = true
      for (let uuid in this.attachments) {
        let uploadResult = ''
        let form = new FormData()
        form.append('path', this.filePath)
        this.$set(this.forms, uuid, { form, result: uploadResult })
        let attachment = this.attachments[uuid]
        let file = this.b64toBlob(attachment.value, attachment.mimeType)
        file.name = attachment.filename
        file.uuid = uuid
        if (this.attachmentsCategories.hasOwnProperty(uuid)) {
          file.categories = Object.keys(this.attachmentsCategories[uuid])
        } else {
          file.categories = []
        }
        this.prepareFile(file, this.addFileToForm, TARGET_FORM)
      }
    },
    upload (uuid) {
      this.fieldCounter += 1
      api.request('storage', 'post', this.url, this.forms[uuid].form)
        .then((response) => {
          this.uploadAssignedCategories(uuid)
        })
        .catch((error) => {
          this.uploadAssignedCategories(uuid, true)
          console.error(error)
        })
    },
    prepareFile (file, callback, target = TARGET_VIEW) {
      let reader = new FileReader()

      reader.onload = function (event) {
        let value = ''
        if (!this.isImage(file.type) || file.type === 'image/svg+xml' || file.type === 'image/tiff') {
          value = event.target.result.split(',')[1]
          callback(file, value)
        } else {
          let img = document.createElement('img')
          img.src = event.target.result
          img.onload = function () {
            let value = this.resize(file, img, target).split(',')[1]
            callback(file, value)
          }.bind(this)
        }
      }.bind(this)
      reader.readAsDataURL(file)
    },
    resize (file, img, target) {
      let imageWidth = this.getImageWidth()
      if (target === TARGET_FORM) {
        imageWidth = this.getImageWidth('min', file.categories)
      }

      let resizeInfo = this.getResizeInfo(img, imageWidth)

      let canvas = document.createElement('canvas')
      let ctx = canvas.getContext('2d')

      canvas.width = resizeInfo.trgWidth
      canvas.height = resizeInfo.trgHeight

      if (
        file.type === 'image/jpeg' ||
        file.type === 'image/tiff' ||
        file.type === 'image/x-tiff'
      ) {
        this.transform(img, canvas, ctx, resizeInfo)
      }

      // This is a bugfix for iOS' scaling bug.
      this.drawImageIOSFix(
        ctx,
        img,
        resizeInfo.srcX != null ? resizeInfo.srcX : 0,
        resizeInfo.srcY != null ? resizeInfo.srcY : 0,
        resizeInfo.srcWidth,
        resizeInfo.srcHeight,
        resizeInfo.trgX != null ? resizeInfo.trgX : 0,
        resizeInfo.trgY != null ? resizeInfo.trgY : 0,
        resizeInfo.trgWidth, resizeInfo.trgHeight
      )

      let resizedImageValue = canvas.toDataURL(file.type, RESIZE_QUALITY)
      resizedImageValue = ExifRestore.restore(img.src, resizedImageValue)
      return resizedImageValue
    },
    transform (img, canvas, ctx, resizeInfo) {
      if (EXIF.getData(img) && EXIF.getTag(img, 'Orientation') !== undefined) {
        let orientation = EXIF.getTag(img, 'Orientation')

        if (orientation > 4) {
          canvas.width = resizeInfo.trgHeight
          canvas.height = resizeInfo.trgWidth
        }

        switch (orientation) {
          case 2:
            // horizontal flip
            ctx.translate(canvas.width, 0)
            ctx.scale(-1, 1)
            break
          case 3:
            // 180° rotate left
            ctx.translate(canvas.width, canvas.height)
            ctx.rotate(Math.PI)
            break
          case 4:
            // vertical flip
            ctx.translate(0, canvas.height)
            ctx.scale(1, -1)
            break
          case 5:
            // vertical flip + 90 rotate right
            ctx.rotate(0.5 * Math.PI)
            ctx.scale(1, -1)
            break
          case 6:
            // 90° rotate right
            ctx.rotate(0.5 * Math.PI)
            ctx.translate(0, -canvas.width)
            break
          case 7:
            // horizontal flip + 90 rotate right
            ctx.rotate(0.5 * Math.PI)
            ctx.translate(canvas.height, -canvas.width)
            ctx.scale(-1, 1)
            break
          case 8:
            // 90° rotate left
            ctx.rotate(-0.5 * Math.PI)
            ctx.translate(-canvas.height, 0)
            break
        }
      }
    },
    /**
     * Those values are going to be used by `ctx.drawImage()`.
     */
    getResizeInfo (file, width, height = null) {
      let info = {
        srcX: 0,
        srcY: 0,
        srcWidth: file.width,
        srcHeight: file.height
      }

      let srcRatio = file.width / file.height

      if (width !== null && srcRatio < 1) {
        height = width
        width = null
      }

      // Automatically calculate dimensions if not specified
      if (width == null && height == null) {
        width = info.srcWidth
        height = info.srcHeight
      } else if (width == null) {
        width = height * srcRatio
      } else if (height == null) {
        height = width / srcRatio
      }

      // Make sure images aren't upscaled
      width = Math.min(width, info.srcWidth)
      height = Math.min(height, info.srcHeight)

      var trgRatio = width / height

      if (info.srcWidth > width || info.srcHeight > height) {
        if (srcRatio > trgRatio) {
          height = width / srcRatio
        } else {
          width = height * srcRatio
        }
      }

      info.srcX = (file.width - info.srcWidth) / 2
      info.srcY = (file.height - info.srcHeight) / 2

      info.trgWidth = width
      info.trgHeight = height

      return info
    },
    getImageWidth (type = 'max', categoryIds = null) {
      let categories = {}
      if (categoryIds !== null) {
        for (let catId in this.categories) {
          if (categoryIds.includes(catId)) {
            categories[catId] = this.categories[catId]
          }
        }
      } else {
        categories = this.categories
      }

      let width = type === 'max' ? 0 : DEFAULT_WIDTH

      for (let catId in categories) {
        if (categories[catId].imageMaxWidth !== null) {
          width = Math[type](width, categories[catId].imageMaxWidth)
        }
      }

      return width
    },
    // Detecting vertical squash in loaded image.
    // Fixes a bug which squash image vertically while drawing into canvas for some images.
    // This is a bug in iOS6 devices. This function from https://github.com/stomita/ios-imagefile-megapixel
    detectVerticalSquash (img) {
      let ih = img.naturalHeight
      let canvas = document.createElement('canvas')
      canvas.width = 1
      canvas.height = ih
      let ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0)

      let _ctx$getImageData = ctx.getImageData(1, 0, 1, ih)
      let data = _ctx$getImageData.data

      // search image edge pixel position in case it is squashed vertically.
      let sy = 0
      let ey = ih
      let py = ih
      while (py > sy) {
        let alpha = data[(py - 1) * 4 + 3]

        if (alpha === 0) {
          ey = py
        } else {
          sy = py
        }

        py = ey + sy >> 1
      }
      let ratio = py / ih

      if (ratio === 0) {
        return 1
      } else {
        return ratio
      }
    },
    // A replacement for context.drawImage
    // (args are for source and destination).
    drawImageIOSFix (ctx, img, sx, sy, sw, sh, dx, dy, dw, dh) {
      let vertSquashRatio = this.detectVerticalSquash(img)
      return ctx.drawImage(img, sx, sy, sw, sh, dx, dy, dw, dh / vertSquashRatio)
    },
    b64toBlob (b64Data, contentType = '', sliceSize = 512) {
      let byteCharacters = atob(b64Data)
      let byteArrays = []

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)

        byteArrays.push(byteArray)
      }

      const blob = new Blob(byteArrays, { type: contentType })
      return blob
    },
    uuidv4 () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = Math.random() * 16 | 0
        let v = c === 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
      })
    }
  }
}
