<template>
  <base-form-modal
    :show="show"
    :title="'Dodaj komunikat do rozliczenia'"
    :ok-text="'Prześlij do weryfikacji'"
    :cancel-text="cancelText"
    @show="open"
    @close="$emit('close')"
    @beforeSubmit="bool => beforeSubmitTrigger = bool"
  >
    <form-modal
      ref="form"
      v-model="model"
      :is-before-submit="beforeSubmitTrigger"
    />
  </base-form-modal>
</template>

<script>
import BaseFormModal from '../../../../../share/modal/BaseFormModal'
import FormModalMixin from '../../../../../share/modal/FormModalMixin'
import FormModal from './ChangeSettlementStateForm'
import ValidatorReset from '../../../../../share/mixins/ValidatorReset'
import {mapActions} from 'vuex'

export default {
  components: {
    FormModal,
    BaseFormModal
  },
  mixins: [
    FormModalMixin,
    ValidatorReset
  ],
  props: {
    service: { type: String, default: () => '' },
    state: { type: String, default: () => '' }
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    ...mapActions([
      'changeSettlementStateWithComment'
    ]),
    async submitForm () {
      try {
        await this.changeSettlementStateWithComment({id: this.$route.params.id, comment: this.model.comment, settlementState: this.state, service: this.service})
        this.$events.emit('settlement:refresh')
      } catch (e) {
        this.errorNotify(e)
      }
    }
  }
}
</script>
