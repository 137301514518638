import { render, staticRenderFns } from "./RefuseCommentForm.vue?vue&type=template&id=9a24e406&"
import script from "./RefuseCommentForm.vue?vue&type=script&lang=js&"
export * from "./RefuseCommentForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports