export default {
  computed: {
    tabThirdMenu () {
      return {
        0: [
          {
            event: { name: `${this.service}:state:verify` },
            icon: 'plus',
            label: 'Prześlij do weryfikacji',
            acl: {
              service: this.service,
              action: ['put_settlement__default__own'],
              allowOneOfActions: true
            }
          },
          {
            event: { name: `${this.service}:state:verifyWithInvoice` },
            icon: 'plus',
            label: 'Prześlij do akceptacji',
            acl: {
              service: this.service,
              action: ['put_settlement__default__own'],
              allowOneOfActions: true
            }
          },
          {
            event: { name: `${this.service}:get:confirmation` },
            icon: 'plus',
            label: 'Pobierz potwierdzenie',
            acl: {
              service: this.service,
              action: ['get_settlement'],
              allowOneOfActions: true
            }
          },
          {
            event: { name: `${this.service}:get:report` },
            icon: 'plus',
            label: 'Pobierz raport',
            acl: {
              service: this.service,
              action: ['get_settlement'],
              allowOneOfActions: true
            }
          },
          {
            event: { name: 'dashboard:menu:redirect', value: `settlements` },
            icon: 'arrow-left',
            label: 'Wróć do listy',
            acl: { service: 'finance', action: ['get_settlements_datatables'], allowOneOfActions: true }
          }
        ],
        1: [
          {
            event: { name: `${this.service}:state:refuse` },
            icon: 'minus',
            label: 'Uzupełnij',
            acl: {
              service: this.service,
              action: 'put_settlement__default__all'
            }
          },
          {
            event: { name: `${this.service}:state:accept` },
            icon: 'plus',
            label: 'Zaakceptuj',
            acl: {
              service: this.service,
              action: ['put_settlement__default__all']
            }
          },
          {
            event: { name: `${this.service}:get:confirmation` },
            icon: 'plus',
            label: 'Pobierz potwierdzenie',
            acl: {
              service: this.service,
              action: ['get_settlement'],
              allowOneOfActions: true
            }
          },
          {
            event: { name: `${this.service}:get:report` },
            icon: 'plus',
            label: 'Pobierz raport',
            acl: {
              service: this.service,
              action: ['get_settlement'],
              allowOneOfActions: true
            }
          },
          {
            event: { name: 'dashboard:menu:redirect', value: `settlements` },
            icon: 'arrow-left',
            label: 'Wróć do listy',
            acl: { service: 'finance', action: ['get_settlements_datatables'], allowOneOfActions: true }
          }
        ],
        2: [
          {
            event: { name: `${this.service}:state:refuse` },
            icon: 'minus',
            label: 'Uzupełnij',
            acl: {
              service: this.service,
              action: 'put_settlement__default__all'
            }
          },
          {
            event: { name: `${this.service}:state:accept` },
            icon: 'plus',
            label: 'Zaakceptuj',
            acl: {
              service: this.service,
              action: ['put_settlement__default__all']
            }
          },
          {
            event: { name: `${this.service}:get:confirmation` },
            icon: 'plus',
            label: 'Pobierz potwierdzenie',
            acl: {
              service: this.service,
              action: ['get_settlement'],
              allowOneOfActions: true
            }
          },
          {
            event: { name: `${this.service}:get:report` },
            icon: 'plus',
            label: 'Pobierz raport',
            acl: {
              service: this.service,
              action: ['get_settlement'],
              allowOneOfActions: true
            }
          },
          {
            event: { name: 'dashboard:menu:redirect', value: `settlements` },
            icon: 'arrow-left',
            label: 'Wróć do listy',
            acl: { service: 'finance', action: ['get_settlements_datatables'], allowOneOfActions: true }
          }
        ],
        3: [
          {
            event: { name: `${this.service}:get:confirmation` },
            icon: 'plus',
            label: 'Pobierz potwierdzenie',
            acl: {
              service: this.service,
              action: ['get_settlement'],
              allowOneOfActions: true
            }
          },
          {
            event: { name: `${this.service}:get:report` },
            icon: 'plus',
            label: 'Pobierz raport',
            acl: {
              service: this.service,
              action: ['get_settlement'],
              allowOneOfActions: true
            }
          },
          {
            event: { name: 'dashboard:menu:redirect', value: `settlements` },
            icon: 'arrow-left',
            label: 'Wróć do listy',
            acl: { service: 'finance', action: ['get_settlements_datatables'], allowOneOfActions: true }
          }
        ]
      }
    }
  },
  methods: {
    reactToStateChange (arg) {

      let menu = []
      if (this.tabThirdMenu.hasOwnProperty(arg)) {
        menu = this.tabThirdMenu[arg]
      }
      this.$events.$emit('dashboard:menu:settlementTab', menu)
    }
  }
}
