<template>
  <form>
    <div
      class="form-group required-field"
      :class="[{ 'has-error': vErrors.first('comment'), 'has-success' : !vErrors.first('comment') && value['comment'] }]"
    >
      <label for="message">
        Treść
      </label>
      <textarea
        id="message"
        v-model="value['comment']"
        v-validate="{rules: {}}"
        name="message"
        class="form-control"
        cols="60"
        rows="6"
        placeholder="Wpisz treść..."
        data-vv-as="komunikat"
      />
      <span
        v-show="vErrors.has('message')"
        class="help-block"
      >{{ vErrors.first('comment') }}</span>
    </div>
  </form>
</template>

<script>
import FormModalValidation from '../../../../../share/form/FormModalValidation'

export default {
  mixins: [
    FormModalValidation
  ],
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
