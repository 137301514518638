import i18n from '@/translations/internationalisation.js'
import fileUtil from '@/store/attachmentsState/utils/file.js'

export default {
  data () {
    return {
      m$allFiles: 0,
      m$files: [],
      m$loadingAttachments: false,
      m$aggregator: 0
    }
  },
  methods: {
    checkType (file) {
      return file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/tiff' ||
        file.type === 'image/x-tiff'
    },
    loadFiles (files) {
      let filesBuffer = []
      const checkEndCounting = (resolve) => {
        this.m$aggregator = this.m$aggregator + 1

        if (this.m$aggregator === files.length) {
          resolve(filesBuffer)
        }
      }
      return new Promise((resolve => {
        for (var i = 0; i < files.length; i++) {
          let file = files[i]
          let extensionsString = '.doc, .docx, .rtf, .pdf, .ods, .odt, .xls, .xlsx, .csv, .txt, .eml, .msg, .xlsm, .7z, .zip, .rar, .gz, .tar, .bmp, .jpeg, .jpg, .tif, .tiff, .png, .PNG, .gif, xml, .signPro, .SIGNPR, .DWG, .crw, .ath, .szf, .axx, .xades, .GPG, .pgp, .sig, .sko, .eie, .sie, .mp3'
          let extensions = extensionsString.split(', ')
          let splittedFileName = file.name.split('.')
          let extension = splittedFileName[splittedFileName.length - 1]
          if (!extensions.some(ext => ext.toLocaleLowerCase() === `.${extension.toLowerCase()}`)) {
            this.$notify({
              type: 'error',
              duration: -1,
              text: i18n.t('pages.taskDetails.attachments.fileUploadNotAllowedExtension', {extension: extension})
            })
            filesBuffer.push(null)
            checkEndCounting(resolve)
            continue
          }
          let fileReader = new FileReader()
          fileReader.onload = (e) => {
            let fileObj = {
              file: file,
              name: file.name,
              type: file.type
            }
            if (this.checkType(file)) {
              let img = new Image()
              img.src = e.target.result
              img.onload = () => {
                fileObj['fileContent'] = fileUtil.resize(file, img, 2, [])
                filesBuffer.push(fileObj)
                checkEndCounting(resolve)
              }
              return
            } else {
              fileObj['fileContent'] = e.target.result
            }
            filesBuffer.push(fileObj)
            checkEndCounting(resolve)
          }
          fileReader.onprogress = (e) => {
            console.log(e)
          }
          fileReader.readAsDataURL(file)
        }
      }))
    },
    async handleFilesLoaded (files) {
      this.m$allFiles = files.length
      this.m$aggregator = 0
      this.m$loadingAttachments = true
      this.m$files = []
      let filesBuffer = []
      filesBuffer = await this.loadFiles(files)
      console.log(filesBuffer)
      this.m$files = filesBuffer.filter(el => el)
      this.m$loadingAttachments = false
    },
    handleDeleteUploadedFile (fileToDelete) {
      this.m$files = this.m$files.filter(file => file.name !== fileToDelete.name)
    }

  }
}
