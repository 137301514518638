<template>
  <form>
    <select-type
      v-if="transitionsArrayLength > 1"
      ref="transitionSelect"
      v-model="value['selectedTransitionName']"
      v-validate="{ rules: {required: true} }"
      name="transition"
      label="Rodzaj tranzycji"
      :required="true"
      :disabled="!transitionsLoaded"
      :options="transitions"
      :empty-first="true"
      :error-message="vErrors.first('transition')"
      data-vv-as="Rodzaj tranzycji"
      @input="transitionChange"
    />
    <div v-if="transitions.length === 1">
      <p class="fake-label">
        Akcja:
      </p>
      <p class="fake-select-replacement">
        {{ transitions[0].label }}
      </p>
    </div>
    <div v-if="transitions.length === 0">
      <p class="">
      </p>
      <p class="">
        Brak dostępnych tranzycji
      </p>
    </div>
    <div v-if="operations.includes('saveInvoice')">
      <additional-components-invoice
        v-validate="{ rules: {required: true} }"
        :error-message="vErrors.first('invoice')"
        data-vv-as="Dane faktury"
        v-model="value['invoice']" @input="handleEmitInput"
        />
    </div>
  </form>
</template>

<script>
import Loader from '../../../../../share/Loader.js'
import FormModalValidation from '../../../../../share/form/FormModalValidation.js'
import SelectType from '../../../../../share/form/type/SelectType.vue'
import {mapGetters} from 'vuex'
import AdditionalComponentsInvoice
  from '@/components/bundles/settlements/share/ChangeStateModal/components/AdditionalComponentsInvoice.vue'

export default {
  components: {
    AdditionalComponentsInvoice,
    SelectType
  },
  mixins: [
    Loader,
    FormModalValidation
  ],
  props: {
    value: {},
    transitions: {type: Array, required: true, default: () => []},
    transitionsLoaded: {type: Boolean, required: true, default: () => false},
  },
  data() {
    return {
      rules: {
        required: v => !!v || 'Wiadomość jest wymagana'
      },
    }
  },
  computed: {
    transitionsArrayLength() {
      return this.transitions.length
    },
    operations () {
      const transition = this.transitions.find((loadedTransition) => `${loadedTransition.value}` === `${this.value.selectedTransitionName}`)
      return transition && transition.metadata && transition.metadata.operations || []
    },
    ...mapGetters(["getTransitions"]),
  },
  methods: {
    transitionChange(event) {
      this.selectedTransition = event
      this.$emit('changeTransition', event)
      this.handleEmitInput()
    },
    handleEmitInput() {
      this.$emit('input', this.value)
    }
  }
}
</script>
<style lang="scss" scoped>
.fake-label {
  text-align: left;
  color: #6ac13b;
  font-weight: bold;
}

.fake-select-replacement {
  text-align: left;
  font-weight: bold;
  margin-left: 20px;
}
</style>
